import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const TypographyComponent = (props) => {
  const { input, label } = useFieldApi(props);
  const {gridProps, TypographyProps} = props;

  return (
    <Grid item lg={12} {...gridProps}>
      <Typography {...TypographyProps} component="div" id={input.name} dangerouslySetInnerHTML={{ __html: label }}  />
    </Grid>
  )
}

export default TypographyComponent