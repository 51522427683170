import { useFormApi } from "@data-driven-forms/react-form-renderer";

import EastIcon from '@mui/icons-material/East';
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import Row from "@components/Modules/Utils/Row/Row";
import Grid from "@mui/material/Grid";
import { useTranslation } from "next-i18next";

export const StepFormTemplate = ({formFields}) => {
  const { handleSubmit, getState } = useFormApi();
  const { submitting, valid } = getState();

  const isValid = submitting || !valid;

  return (
    <form onSubmit={handleSubmit}>
      <Row spacing={12}>
        { formFields }

        <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={50}>
          <Fab disabled={isValid} type="submit" color={isValid ? "default" : "primary"} variant="circular" aria-label="add">
            <EastIcon fontSize="inherit"/>
          </Fab>
        </Grid>
      </Row>
      

    </form>
  )
}

export const CustomFormTemplate = ({formFields, submitLabel = ''} ) => {

  const { handleSubmit, getState } = useFormApi();
  const { submitting, valid } = getState();
  const isValid = submitting || !valid;
  const {t} = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <Row spacing={12}>
        { formFields }

        <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Button type="submit" variant="contained" color="primary" disabled={isValid}>{submitLabel ? submitLabel : t('contactForm.common.send')}</Button>
        </Grid>
      </Row>
      

    </form>
  )
}

export default CustomFormTemplate;