export const jobsValues = [
  {
      label: "Academy Manager",
      value: "Academy Manager",
      category: "Privato"
  },
  {
      label: "Account",
      value: "Account",
      category: "Privato"
  },
  {
      label: "Account And Project Manager",
      value: "Account And Project Manager",
      category: "Privato"
  },
  {
      label: "Account Business",
      value: "Account Business",
      category: "Privato"
  },
  {
      label: "Account Commerciale",
      value: "Account Commerciale",
      category: "Privato"
  },
  {
      label: "Account Development Manager",
      value: "Account Development Manager",
      category: "Privato"
  },
  {
      label: "Account Director",
      value: "Account Director",
      category: "Privato"
  },
  {
      label: "Account Executive",
      value: "Account Executive",
      category: "Privato"
  },
  {
      label: "Account Manager",
      value: "Account Manager",
      category: "Privato"
  },
  {
      label: "Account Specialist",
      value: "Account Specialist",
      category: "Privato"
  },
  {
      label: "Acquisition & Talent Lead",
      value: "Acquisition & Talent Lead",
      category: "Privato"
  },
  {
      label: "Acquisti",
      value: "Acquisti",
      category: "Privato"
  },
  {
      label: "Addetta Sponsorizzazioni Ed Eventi",
      value: "Addetta Sponsorizzazioni Ed Eventi",
      category: "Privato"
  },
  {
      label: "Administration Director",
      value: "Administration Director",
      category: "Privato"
  },
  {
      label: "Administration Manager",
      value: "Administration Manager",
      category: "Privato"
  },
  {
      label: "Administration Specialist",
      value: "Administration Specialist",
      category: "Privato"
  },
  {
      label: "Administration Staff",
      value: "Administration Staff",
      category: "Privato"
  },
  {
      label: "Administrative General Manager",
      value: "Administrative General Manager",
      category: "Privato"
  },
  {
      label: "Adv Specialist",
      value: "Adv Specialist",
      category: "Privato"
  },
  {
      label: "Advertising Manager",
      value: "Advertising Manager",
      category: "Privato"
  },
  {
      label: "Advisor",
      value: "Advisor",
      category: "Privato"
  },
  {
      label: "Affari Istituzionali",
      value: "Affari Istituzionali",
      category: "Privato"
  },
  {
      label: "Affari Istituzionali Territoriali",
      value: "Affari Istituzionali Territoriali",
      category: "Privato"
  },
  {
      label: "After Sales & Logistic Manager",
      value: "After Sales & Logistic Manager",
      category: "Privato"
  },
  {
      label: "Agency Coordinator",
      value: "Agency Coordinator",
      category: "Privato"
  },
  {
      label: "Agente",
      value: "Agente",
      category: "Privato"
  },
  {
      label: "Agente Di Commercio",
      value: "Agente Di Commercio",
      category: "Privato"
  },
  {
      label: "Agenzia Comunicazione",
      value: "Agenzia Comunicazione",
      category: "Privato"
  },
  {
      label: "Alliance Manager",
      value: "Alliance Manager",
      category: "Privato"
  },
  {
      label: "Altro",
      value: "Altro",
      category: "Privato/Pubblico"
  },
  {
      label: "Amministratore",
      value: "Amministratore",
      category: "Privato/Pubblico"
  },
  {
      label: "Amministratore Delegato",
      value: "Amministratore Delegato",
      category: "Privato/Pubblico"
  },
  {
      label: "Amministratore Unico ",
      value: "Amministratore Unico ",
      category: "Privato"
  },
  {
      label: "Amministrazione Finanza e Controllo",
      value: "Amministrazione Finanza e Controllo",
      category: "Privato"
  },
  {
      label: "Analyst",
      value: "Analyst",
      category: "Privato"
  },
  {
      label: "Application Manager",
      value: "Application Manager",
      category: "Privato"
  },
  {
      label: "Architecture Sales Leader",
      value: "Architecture Sales Leader",
      category: "Privato"
  },
  {
      label: "Architetto",
      value: "Architetto",
      category: "Privato"
  },
  {
      label: "Architetto Sistemi Informativi",
      value: "Architetto Sistemi Informativi",
      category: "Privato"
  },
  {
      label: "Archivista",
      value: "Archivista",
      category: "Privato"
  },
  {
      label: "Area Manager",
      value: "Area Manager",
      category: "Privato"
  },
  {
      label: "Art Director",
      value: "Art Director",
      category: "Privato"
  },
  {
      label: "Assessore",
      value: "Assessore",
      category: "Pubblico"
  },
  {
      label: "Asset Operations Specialist",
      value: "Asset Operations Specialist",
      category: "Privato"
  },
  {
      label: "Assistant Manager",
      value: "Assistant Manager",
      category: "Privato"
  },
  {
      label: "Assistente",
      value: "Assistente",
      category: "Privato/Pubblico"
  },
  {
      label: "Assistente Amministrativa",
      value: "Assistente Amministrativa",
      category: "Privato"
  },
  {
      label: "Associate",
      value: "Associate",
      category: "Privato"
  },
  {
      label: "Associate Manager",
      value: "Associate Manager",
      category: "Privato"
  },
  {
      label: "Associate Partner ",
      value: "Associate Partner ",
      category: "Privato"
  },
  {
      label: "Associate Product Manager",
      value: "Associate Product Manager",
      category: "Privato"
  },
  {
      label: "Associate Project Manager",
      value: "Associate Project Manager",
      category: "Privato"
  },
  {
      label: "Automazione Industriale",
      value: "Automazione Industriale",
      category: "Privato"
  },
  {
      label: "Avvocato",
      value: "Avvocato",
      category: "Privato"
  },
  {
      label: "Board Member",
      value: "Board Member",
      category: "Privato"
  },
  {
      label: "Brand Communication Manager",
      value: "Brand Communication Manager",
      category: "Privato"
  },
  {
      label: "Brand Manager",
      value: "Brand Manager",
      category: "Privato"
  },
  {
      label: "Brand Specialist",
      value: "Brand Specialist",
      category: "Privato"
  },
  {
      label: "Bu Manager",
      value: "Bu Manager",
      category: "Privato"
  },
  {
      label: "Building Utilities",
      value: "Building Utilities",
      category: "Privato"
  },
  {
      label: "Business & Executive Coach",
      value: "Business & Executive Coach",
      category: "Privato"
  },
  {
      label: "Business & Territory Manager",
      value: "Business & Territory Manager",
      category: "Privato"
  },
  {
      label: "Business Administration Manager",
      value: "Business Administration Manager",
      category: "Privato"
  },
  {
      label: "Business Advisor",
      value: "Business Advisor",
      category: "Privato"
  },
  {
      label: "Business Analyst",
      value: "Business Analyst",
      category: "Privato"
  },
  {
      label: "Business Consultant",
      value: "Business Consultant",
      category: "Privato"
  },
  {
      label: "Business Controller",
      value: "Business Controller",
      category: "Privato"
  },
  {
      label: "Business Controlling Director",
      value: "Business Controlling Director",
      category: "Privato"
  },
  {
      label: "Business Developer",
      value: "Business Developer",
      category: "Privato"
  },
  {
      label: "Business Development",
      value: "Business Development",
      category: "Privato"
  },
  {
      label: "Business Development Consultant",
      value: "Business Development Consultant",
      category: "Privato"
  },
  {
      label: "Business Development Director",
      value: "Business Development Director",
      category: "Privato"
  },
  {
      label: "Business Development Manager",
      value: "Business Development Manager",
      category: "Privato"
  },
  {
      label: "Business Development Representative ",
      value: "Business Development Representative ",
      category: "Privato"
  },
  {
      label: "Business Development Support",
      value: "Business Development Support",
      category: "Privato"
  },
  {
      label: "Business Director",
      value: "Business Director",
      category: "Privato"
  },
  {
      label: "Business Engineer",
      value: "Business Engineer",
      category: "Privato"
  },
  {
      label: "Business Excellence Manager",
      value: "Business Excellence Manager",
      category: "Privato"
  },
  {
      label: "Business Intelligence Manager",
      value: "Business Intelligence Manager",
      category: "Privato"
  },
  {
      label: "Business Intelligence Specialist",
      value: "Business Intelligence Specialist",
      category: "Privato"
  },
  {
      label: "Business Manager",
      value: "Business Manager",
      category: "Privato"
  },
  {
      label: "Business Manager - Operations",
      value: "Business Manager - Operations",
      category: "Privato"
  },
  {
      label: "Business Operation Manager ",
      value: "Business Operation Manager ",
      category: "Privato"
  },
  {
      label: "Business Operations & Procurement Management",
      value: "Business Operations & Procurement Management",
      category: "Privato"
  },
  {
      label: "Business Operations Director",
      value: "Business Operations Director",
      category: "Privato"
  },
  {
      label: "Business Partner",
      value: "Business Partner",
      category: "Privato"
  },
  {
      label: "Business Presales",
      value: "Business Presales",
      category: "Privato"
  },
  {
      label: "Business Process Innovation",
      value: "Business Process Innovation",
      category: "Privato"
  },
  {
      label: "Business Specialist",
      value: "Business Specialist",
      category: "Privato"
  },
  {
      label: "Business Staff",
      value: "Business Staff",
      category: "Privato"
  },
  {
      label: "Business Strategy",
      value: "Business Strategy",
      category: "Privato"
  },
  {
      label: "Business Support Partner ",
      value: "Business Support Partner ",
      category: "Privato"
  },
  {
      label: "Business System Analysis Professional",
      value: "Business System Analysis Professional",
      category: "Privato"
  },
  {
      label: "Business Unit Manager",
      value: "Business Unit Manager",
      category: "Privato"
  },
  {
      label: "Businness Developer",
      value: "Businness Developer",
      category: "Privato"
  },
  {
      label: "Buyer",
      value: "Buyer",
      category: "Privato"
  },
  {
      label: "Capacity Building Consultant",
      value: "Capacity Building Consultant",
      category: "Privato"
  },
  {
      label: "Capo Divsione",
      value: "Capo Divsione",
      category: "Privato"
  },
  {
      label: "Capo Mercato",
      value: "Capo Mercato",
      category: "Privato"
  },
  {
      label: "Caporedattore",
      value: "Caporedattore",
      category: "Privato"
  },
  {
      label: "Career Advisor",
      value: "Career Advisor",
      category: "Privato"
  },
  {
      label: "CBO / Business Director",
      value: "CBO / Business Director",
      category: "Privato"
  },
  {
      label: "CCO / Direttore Comunicazione",
      value: "CCO / Direttore Comunicazione",
      category: "Privato"
  },
  {
      label: "CDO / Digital Director",
      value: "CDO / Digital Director",
      category: "Privato"
  },
  {
      label: "CEO",
      value: "CEO",
      category: "Privato"
  },
  {
      label: "Ceo Assistant",
      value: "Ceo Assistant",
      category: "Privato"
  },
  {
      label: "CFO",
      value: "CFO",
      category: "Privato"
  },
  {
      label: "Chairman",
      value: "Chairman",
      category: "Privato"
  },
  {
      label: "Channel Director",
      value: "Channel Director",
      category: "Privato"
  },
  {
      label: "Channel Manager",
      value: "Channel Manager",
      category: "Privato"
  },
  {
      label: "Chief Client Officer",
      value: "Chief Client Officer",
      category: "Privato"
  },
  {
      label: "Chief Commercial Officer",
      value: "Chief Commercial Officer",
      category: "Privato"
  },
  {
      label: "Chief Digital Officer",
      value: "Chief Digital Officer",
      category: "Privato"
  },
  {
      label: "Chief Executive Officer",
      value: "Chief Executive Officer",
      category: "Privato"
  },
  {
      label: "Chief Experience Officer",
      value: "Chief Experience Officer",
      category: "Privato"
  },
  {
      label: "Chief Human Resources Officer",
      value: "Chief Human Resources Officer",
      category: "Privato"
  },
  {
      label: "Chief Information Officer",
      value: "Chief Information Officer",
      category: "Privato"
  },
  {
      label: "Chief Innovation Officer",
      value: "Chief Innovation Officer",
      category: "Privato"
  },
  {
      label: "Chief Marketing And Product Officer",
      value: "Chief Marketing And Product Officer",
      category: "Privato"
  },
  {
      label: "Chief Marketing Officer",
      value: "Chief Marketing Officer",
      category: "Privato"
  },
  {
      label: "Chief Of R&D & Tech.",
      value: "Chief Of R&D & Tech.",
      category: "Privato"
  },
  {
      label: "Chief Operating Officer",
      value: "Chief Operating Officer",
      category: "Privato"
  },
  {
      label: "Chief Sales Officer",
      value: "Chief Sales Officer",
      category: "Privato"
  },
  {
      label: "Chief Sales Strategy Officer",
      value: "Chief Sales Strategy Officer",
      category: "Privato"
  },
  {
      label: "Chief Technical Officer",
      value: "Chief Technical Officer",
      category: "Privato"
  },
  {
      label: "Cio & It Technology Manager ",
      value: "Cio & It Technology Manager ",
      category: "Privato"
  },
  {
      label: "Client Director",
      value: "Client Director",
      category: "Privato"
  },
  {
      label: "Client Executive",
      value: "Client Executive",
      category: "Privato"
  },
  {
      label: "Client Manager",
      value: "Client Manager",
      category: "Privato"
  },
  {
      label: "Client Services Manager",
      value: "Client Services Manager",
      category: "Privato"
  },
  {
      label: "Cloud Architect",
      value: "Cloud Architect",
      category: "Privato"
  },
  {
      label: "Cloud Data Management Sales Specialist",
      value: "Cloud Data Management Sales Specialist",
      category: "Privato"
  },
  {
      label: "Cloud Solution Manager",
      value: "Cloud Solution Manager",
      category: "Privato"
  },
  {
      label: "Cloud Solutions Consultant",
      value: "Cloud Solutions Consultant",
      category: "Privato"
  },
  {
      label: "CMO / Direttore Marketing",
      value: "CMO / Direttore Marketing",
      category: "Privato"
  },
  {
      label: "Coach & Trainer",
      value: "Coach & Trainer",
      category: "Privato"
  },
  {
      label: "Co-Founder",
      value: "Co-Founder",
      category: "Privato"
  },
  {
      label: "Co-Founder And Chief Sales & Marketing Officer",
      value: "Co-Founder And Chief Sales & Marketing Officer",
      category: "Privato"
  },
  {
      label: "Collaboratore",
      value: "Collaboratore",
      category: "Privato"
  },
  {
      label: "Collaboratore Amministrativo",
      value: "Collaboratore Amministrativo",
      category: "Privato"
  },
  {
      label: "Commercial & Strategy Director",
      value: "Commercial & Strategy Director",
      category: "Privato"
  },
  {
      label: "Commercial Director",
      value: "Commercial Director",
      category: "Privato"
  },
  {
      label: "Commercial Intelligence & Demand Manager",
      value: "Commercial Intelligence & Demand Manager",
      category: "Privato"
  },
  {
      label: "Commercial Manager",
      value: "Commercial Manager",
      category: "Privato"
  },
  {
      label: "Commercial Operations & Facility Management Director",
      value: "Commercial Operations & Facility Management Director",
      category: "Privato"
  },
  {
      label: "Commerciale",
      value: "Commerciale",
      category: "Privato"
  },
  {
      label: "Commercialista",
      value: "Commercialista",
      category: "Privato"
  },
  {
      label: "Commodity Manager",
      value: "Commodity Manager",
      category: "Privato"
  },
  {
      label: "Communication & External Relations",
      value: "Communication & External Relations",
      category: "Privato"
  },
  {
      label: "Communication & Marketing",
      value: "Communication & Marketing",
      category: "Privato"
  },
  {
      label: "Communication & Social Media Marketing Manager",
      value: "Communication & Social Media Marketing Manager",
      category: "Privato"
  },
  {
      label: "Communication Assistant",
      value: "Communication Assistant",
      category: "Privato"
  },
  {
      label: "Communication Director",
      value: "Communication Director",
      category: "Privato"
  },
  {
      label: "Communication Manager",
      value: "Communication Manager",
      category: "Privato"
  },
  {
      label: "Communication Specialist",
      value: "Communication Specialist",
      category: "Privato"
  },
  {
      label: "Communication Staff",
      value: "Communication Staff",
      category: "Privato"
  },
  {
      label: "Community Manager",
      value: "Community Manager",
      category: "Privato"
  },
  {
      label: "Compensation Senior Specialist",
      value: "Compensation Senior Specialist",
      category: "Privato"
  },
  {
      label: "Compliance",
      value: "Compliance",
      category: "Privato"
  },
  {
      label: "Compliance & Risk Manager",
      value: "Compliance & Risk Manager",
      category: "Privato"
  },
  {
      label: "Componente Del Consiglio Di Amministrazione",
      value: "Componente Del Consiglio Di Amministrazione",
      category: "Privato"
  },
  {
      label: "Comunicazione",
      value: "Comunicazione",
      category: "Privato"
  },
  {
      label: "Comunicazione E Marketing",
      value: "Comunicazione E Marketing",
      category: "Privato"
  },
  {
      label: "Consigliere",
      value: "Consigliere",
      category: "Privato/Pubblico"
  },
  {
      label: "Consigliere Delegato",
      value: "Consigliere Delegato",
      category: "Privato"
  },
  {
      label: "Consigliere Nazionale",
      value: "Consigliere Nazionale",
      category: "Privato"
  },
  {
      label: "Consulente",
      value: "Consulente",
      category: "Privato/Pubblico"
  },
  {
      label: "Consultant",
      value: "Consultant",
      category: "Privato"
  },
  {
      label: "Consulting Manager",
      value: "Consulting Manager",
      category: "Privato"
  },
  {
      label: "Content Editor",
      value: "Content Editor",
      category: "Privato"
  },
  {
      label: "Content Manager",
      value: "Content Manager",
      category: "Privato"
  },
  {
      label: "Continous Improvement Manager",
      value: "Continous Improvement Manager",
      category: "Privato"
  },
  {
      label: "Control Room Senior Manager",
      value: "Control Room Senior Manager",
      category: "Privato"
  },
  {
      label: "Controller",
      value: "Controller",
      category: "Privato"
  },
  {
      label: "Controllo Di Gestione",
      value: "Controllo Di Gestione",
      category: "Privato"
  },
  {
      label: "Coo",
      value: "Coo",
      category: "Privato"
  },
  {
      label: "Coo Performance Division",
      value: "Coo Performance Division",
      category: "Privato"
  },
  {
      label: "Coordinatore",
      value: "Coordinatore",
      category: "Privato"
  },
  {
      label: "Corporate Communication Manager",
      value: "Corporate Communication Manager",
      category: "Privato"
  },
  {
      label: "Corporate Marketing & Communication Director",
      value: "Corporate Marketing & Communication Director",
      category: "Privato"
  },
  {
      label: "Corporate Relationship Manager",
      value: "Corporate Relationship Manager",
      category: "Privato"
  },
  {
      label: "Corporate Sales Manager",
      value: "Corporate Sales Manager",
      category: "Privato"
  },
  {
      label: "Corporate Strategy",
      value: "Corporate Strategy",
      category: "Privato"
  },
  {
      label: "Corporate, Large Account & Public Sector Marketing Director",
      value: "Corporate, Large Account & Public Sector Marketing Director",
      category: "Privato"
  },
  {
      label: "Cost & Resources Department Manager",
      value: "Cost & Resources Department Manager",
      category: "Privato"
  },
  {
      label: "Counsel",
      value: "Counsel",
      category: "Privato"
  },
  {
      label: "Country Director",
      value: "Country Director",
      category: "Privato"
  },
  {
      label: "Country Finance Manager",
      value: "Country Finance Manager",
      category: "Privato"
  },
  {
      label: "Country Manager",
      value: "Country Manager",
      category: "Privato"
  },
  {
      label: "Country Marketing Manager ",
      value: "Country Marketing Manager ",
      category: "Privato"
  },
  {
      label: "Country Marketing Specialist",
      value: "Country Marketing Specialist",
      category: "Privato"
  },
  {
      label: "Country People & Organization Human Resources Head ",
      value: "Country People & Organization Human Resources Head ",
      category: "Privato"
  },
  {
      label: "Country Sales Manager",
      value: "Country Sales Manager",
      category: "Privato"
  },
  {
      label: "Country Supply Manager",
      value: "Country Supply Manager",
      category: "Privato"
  },
  {
      label: "Country Tax Director",
      value: "Country Tax Director",
      category: "Privato"
  },
  {
      label: "Creative Director",
      value: "Creative Director",
      category: "Privato"
  },
  {
      label: "CRM Manager",
      value: "CRM Manager",
      category: "Privato"
  },
  {
      label: "CRM Specialist",
      value: "CRM Specialist",
      category: "Privato"
  },
  {
      label: "CSR Manager",
      value: "CSR Manager",
      category: "Privato"
  },
  {
      label: "CSR Specialist",
      value: "CSR Specialist",
      category: "Privato"
  },
  {
      label: "Cto & Co-Founder",
      value: "Cto & Co-Founder",
      category: "Privato"
  },
  {
      label: "CTO / Direttore Tecnico",
      value: "CTO / Direttore Tecnico",
      category: "Privato"
  },
  {
      label: "Customer & Marketing",
      value: "Customer & Marketing",
      category: "Privato"
  },
  {
      label: "Customer Advisor",
      value: "Customer Advisor",
      category: "Privato"
  },
  {
      label: "Customer And Product Support",
      value: "Customer And Product Support",
      category: "Privato"
  },
  {
      label: "Customer Area",
      value: "Customer Area",
      category: "Privato"
  },
  {
      label: "Customer Care",
      value: "Customer Care",
      category: "Privato"
  },
  {
      label: "Customer Engagement Manager",
      value: "Customer Engagement Manager",
      category: "Privato"
  },
  {
      label: "Customer Marketing",
      value: "Customer Marketing",
      category: "Privato"
  },
  {
      label: "Customer Satisfaction And Claims",
      value: "Customer Satisfaction And Claims",
      category: "Privato"
  },
  {
      label: "Customer Service",
      value: "Customer Service",
      category: "Privato"
  },
  {
      label: "Customer Service Management Director",
      value: "Customer Service Management Director",
      category: "Privato"
  },
  {
      label: "Customer Service Manager",
      value: "Customer Service Manager",
      category: "Privato"
  },
  {
      label: "Customer Service Specialist",
      value: "Customer Service Specialist",
      category: "Privato"
  },
  {
      label: "Customer Success Account Manager",
      value: "Customer Success Account Manager",
      category: "Privato"
  },
  {
      label: "Customer Success Director",
      value: "Customer Success Director",
      category: "Privato"
  },
  {
      label: "Customer Value Management Director",
      value: "Customer Value Management Director",
      category: "Privato"
  },
  {
      label: "Data & Reporting Management",
      value: "Data & Reporting Management",
      category: "Privato"
  },
  {
      label: "Data Analyst",
      value: "Data Analyst",
      category: "Privato"
  },
  {
      label: "Data Communication Manager",
      value: "Data Communication Manager",
      category: "Privato"
  },
  {
      label: "Data Governance Analyst",
      value: "Data Governance Analyst",
      category: "Privato"
  },
  {
      label: "Data Manager",
      value: "Data Manager",
      category: "Privato"
  },
  {
      label: "Data Processing Manager",
      value: "Data Processing Manager",
      category: "Privato"
  },
  {
      label: "Data Processing Specialist",
      value: "Data Processing Specialist",
      category: "Privato"
  },
  {
      label: "Data Protection Manager",
      value: "Data Protection Manager",
      category: "Privato"
  },
  {
      label: "Data Protection Officer",
      value: "Data Protection Officer",
      category: "Privato"
  },
  {
      label: "Data Protection Specialist",
      value: "Data Protection Specialist",
      category: "Privato"
  },
  {
      label: "Data Science",
      value: "Data Science",
      category: "Privato"
  },
  {
      label: "Data Scientist",
      value: "Data Scientist",
      category: "Privato"
  },
  {
      label: "Data Specialist",
      value: "Data Specialist",
      category: "Privato"
  },
  {
      label: "Database Administrator",
      value: "Database Administrator",
      category: "Privato"
  },
  {
      label: "Database Manager",
      value: "Database Manager",
      category: "Privato"
  },
  {
      label: "Database Specialist",
      value: "Database Specialist",
      category: "Privato"
  },
  {
      label: "Delivery Manager",
      value: "Delivery Manager",
      category: "Privato"
  },
  {
      label: "Demand Manager",
      value: "Demand Manager",
      category: "Privato"
  },
  {
      label: "Department Manager Mass Market",
      value: "Department Manager Mass Market",
      category: "Privato"
  },
  {
      label: "Deputato",
      value: "Deputato",
      category: "Pubblico"
  },
  {
      label: "Deputy General Manager",
      value: "Deputy General Manager",
      category: "Privato"
  },
  {
      label: "Designer",
      value: "Designer",
      category: "Privato"
  },
  {
      label: "Developer",
      value: "Developer",
      category: "Privato"
  },
  {
      label: "Development Director",
      value: "Development Director",
      category: "Privato"
  },
  {
      label: "Digital & Communication Specialist",
      value: "Digital & Communication Specialist",
      category: "Privato"
  },
  {
      label: "Digital & Data Director",
      value: "Digital & Data Director",
      category: "Privato"
  },
  {
      label: "Digital Acquisition Director",
      value: "Digital Acquisition Director",
      category: "Privato"
  },
  {
      label: "Digital Analyst",
      value: "Digital Analyst",
      category: "Privato"
  },
  {
      label: "Digital Archive Consultant",
      value: "Digital Archive Consultant",
      category: "Privato"
  },
  {
      label: "Digital Content Specialist",
      value: "Digital Content Specialist",
      category: "Privato"
  },
  {
      label: "Digital Data & Modelling Departement Manager",
      value: "Digital Data & Modelling Departement Manager",
      category: "Privato"
  },
  {
      label: "Digital Innovation & Communication Director",
      value: "Digital Innovation & Communication Director",
      category: "Privato"
  },
  {
      label: "Digital Learning Specialist",
      value: "Digital Learning Specialist",
      category: "Privato"
  },
  {
      label: "Digital Manager",
      value: "Digital Manager",
      category: "Privato"
  },
  {
      label: "Digital Marketing Manager",
      value: "Digital Marketing Manager",
      category: "Privato"
  },
  {
      label: "Digital Marketing Specialist",
      value: "Digital Marketing Specialist",
      category: "Privato"
  },
  {
      label: "Digital Platform Manager",
      value: "Digital Platform Manager",
      category: "Privato"
  },
  {
      label: "Digital Product Development Project Leade",
      value: "Digital Product Development Project Leade",
      category: "Privato"
  },
  {
      label: "Digital Product Manager",
      value: "Digital Product Manager",
      category: "Privato"
  },
  {
      label: "Digital Promoter",
      value: "Digital Promoter",
      category: "Privato"
  },
  {
      label: "Digital Sales & Lead, Tribe Leader",
      value: "Digital Sales & Lead, Tribe Leader",
      category: "Privato"
  },
  {
      label: "Digital Specialist",
      value: "Digital Specialist",
      category: "Privato"
  },
  {
      label: "Digital Strategist",
      value: "Digital Strategist",
      category: "Privato"
  },
  {
      label: "Digital Transformation Project Manager",
      value: "Digital Transformation Project Manager",
      category: "Privato"
  },
  {
      label: "Dipendente",
      value: "Dipendente",
      category: "Privato/Pubblico"
  },
  {
      label: "Director",
      value: "Director",
      category: "Privato"
  },
  {
      label: "Director - Special Projects",
      value: "Director - Special Projects",
      category: "Privato"
  },
  {
      label: "Director Business Strategy",
      value: "Director Business Strategy",
      category: "Privato"
  },
  {
      label: "Director Business Transformation",
      value: "Director Business Transformation",
      category: "Privato"
  },
  {
      label: "Director Business Unit",
      value: "Director Business Unit",
      category: "Privato"
  },
  {
      label: "Director Comercial",
      value: "Director Comercial",
      category: "Privato"
  },
  {
      label: "Director Customer Service",
      value: "Director Customer Service",
      category: "Privato"
  },
  {
      label: "Director Engineering",
      value: "Director Engineering",
      category: "Privato"
  },
  {
      label: "Director Finance Planning & Analysis",
      value: "Director Finance Planning & Analysis",
      category: "Privato"
  },
  {
      label: "Director Global Sales Operations",
      value: "Director Global Sales Operations",
      category: "Privato"
  },
  {
      label: "Director In New Technologies Development",
      value: "Director In New Technologies Development",
      category: "Privato"
  },
  {
      label: "Director It Management",
      value: "Director It Management",
      category: "Privato"
  },
  {
      label: "Director Marketing",
      value: "Director Marketing",
      category: "Privato"
  },
  {
      label: "Director Of Applied Sciences",
      value: "Director Of Applied Sciences",
      category: "Privato"
  },
  {
      label: "Director Of Sales & Marketing",
      value: "Director Of Sales & Marketing",
      category: "Privato"
  },
  {
      label: "Director Portfolio Planing",
      value: "Director Portfolio Planing",
      category: "Privato"
  },
  {
      label: "Director Regulatory Affairs",
      value: "Director Regulatory Affairs",
      category: "Privato"
  },
  {
      label: "Direttore",
      value: "Direttore",
      category: "Privato/Pubblico"
  },
  {
      label: "Direttore Acquisti",
      value: "Direttore Acquisti",
      category: "Privato"
  },
  {
      label: "Direttore Affari Istituzionali",
      value: "Direttore Affari Istituzionali",
      category: "Privato"
  },
  {
      label: "Direttore Amministrativo",
      value: "Direttore Amministrativo",
      category: "Privato"
  },
  {
      label: "Direttore Amministrazione E Finanze",
      value: "Direttore Amministrazione E Finanze",
      category: "Privato"
  },
  {
      label: "Direttore Associato",
      value: "Direttore Associato",
      category: "Privato"
  },
  {
      label: "Direttore Business Unit Special Care",
      value: "Direttore Business Unit Special Care",
      category: "Privato"
  },
  {
      label: "Direttore Call Center",
      value: "Direttore Call Center",
      category: "Privato"
  },
  {
      label: "Direttore Commerciale",
      value: "Direttore Commerciale",
      category: "Privato"
  },
  {
      label: "Direttore Comunicazione",
      value: "Direttore Comunicazione",
      category: "Privato"
  },
  {
      label: "Direttore Creativo",
      value: "Direttore Creativo",
      category: "Privato"
  },
  {
      label: "Direttore Del Personale",
      value: "Direttore Del Personale",
      category: "Privato"
  },
  {
      label: "Direttore Delivery Pubblica Amministrazione",
      value: "Direttore Delivery Pubblica Amministrazione",
      category: "Privato"
  },
  {
      label: "Direttore Di Filiale",
      value: "Direttore Di Filiale",
      category: "Privato"
  },
  {
      label: "Direttore di stabilimento",
      value: "Direttore di stabilimento",
      category: "Privato"
  },
  {
      label: "Direttore Editoriale",
      value: "Direttore Editoriale",
      category: "Privato"
  },
  {
      label: "Direttore Finanziario",
      value: "Direttore Finanziario",
      category: "Privato/Pubblico"
  },
  {
      label: "Direttore Funzioni Di Staff",
      value: "Direttore Funzioni Di Staff",
      category: "Privato"
  },
  {
      label: "Direttore Generale",
      value: "Direttore Generale",
      category: "Privato/Pubblico"
  },
  {
      label: "Direttore Hr",
      value: "Direttore Hr",
      category: "Privato"
  },
  {
      label: "Direttore Ingegneria",
      value: "Direttore Ingegneria",
      category: "Privato"
  },
  {
      label: "Direttore Logistica",
      value: "Direttore Logistica",
      category: "Privato"
  },
  {
      label: "Direttore Marketing",
      value: "Direttore Marketing",
      category: "Privato"
  },
  {
      label: "Direttore Marketing & Comunicazione",
      value: "Direttore Marketing & Comunicazione",
      category: "Privato"
  },
  {
      label: "Direttore operativo",
      value: "Direttore operativo",
      category: "Privato"
  },
  {
      label: "Direttore Produzione",
      value: "Direttore Produzione",
      category: "Privato"
  },
  {
      label: "Direttore Retail",
      value: "Direttore Retail",
      category: "Privato"
  },
  {
      label: "Direttore Rete Cloud E Business",
      value: "Direttore Rete Cloud E Business",
      category: "Privato"
  },
  {
      label: "Direttore Ricerca Sviluppo E Innovazione Digitale ",
      value: "Direttore Ricerca Sviluppo E Innovazione Digitale ",
      category: "Privato"
  },
  {
      label: "Direttore Sales & Marketing",
      value: "Direttore Sales & Marketing",
      category: "Privato"
  },
  {
      label: "Direttore Sanitario",
      value: "Direttore Sanitario",
      category: "Privato"
  },
  {
      label: "Direttore Stabilimento",
      value: "Direttore Stabilimento",
      category: "Privato"
  },
  {
      label: "Direttore Supply Chain",
      value: "Direttore Supply Chain",
      category: "Privato"
  },
  {
      label: "Direttore Tecnico",
      value: "Direttore Tecnico",
      category: "Privato"
  },
  {
      label: "Direttore Territoriale",
      value: "Direttore Territoriale",
      category: "Privato"
  },
  {
      label: "Direttore Ufficio Stampa",
      value: "Direttore Ufficio Stampa",
      category: "Privato"
  },
  {
      label: "Direttore Ufficio Tecnico",
      value: "Direttore Ufficio Tecnico",
      category: "Privato"
  },
  {
      label: "Direttore Vendite",
      value: "Direttore Vendite",
      category: "Privato"
  },
  {
      label: "Direttrice Comunicazione, Relazioni Esterne E Sostenibilità",
      value: "Direttrice Comunicazione, Relazioni Esterne E Sostenibilità",
      category: "Privato"
  },
  {
      label: "Direzione Amministrazione",
      value: "Direzione Amministrazione",
      category: "Privato"
  },
  {
      label: "Direzione Aziendale",
      value: "Direzione Aziendale",
      category: "Privato"
  },
  {
      label: "Direzione Commerciale",
      value: "Direzione Commerciale",
      category: "Privato"
  },
  {
      label: "Direzione Generale",
      value: "Direzione Generale",
      category: "Privato"
  },
  {
      label: "Direzione HR",
      value: "Direzione HR",
      category: "Privato"
  },
  {
      label: "Direzione Innovazione & Sviluppo",
      value: "Direzione Innovazione & Sviluppo",
      category: "Privato"
  },
  {
      label: "Direzione Marketing",
      value: "Direzione Marketing",
      category: "Privato"
  },
  {
      label: "Direzione Sistemi Informativi",
      value: "Direzione Sistemi Informativi",
      category: "Privato"
  },
  {
      label: "Direzione Tecnica",
      value: "Direzione Tecnica",
      category: "Privato"
  },
  {
      label: "Direzione Vendite",
      value: "Direzione Vendite",
      category: "Privato"
  },
  {
      label: "Dirigente",
      value: "Dirigente",
      category: "Privato/Pubblico"
  },
  {
      label: "Dirigente Account Manager",
      value: "Dirigente Account Manager",
      category: "Privato"
  },
  {
      label: "Dirigente Amministrazione, Finanza E Controllo",
      value: "Dirigente Amministrazione, Finanza E Controllo",
      category: "Privato"
  },
  {
      label: "Dirigente Aziendale ",
      value: "Dirigente Aziendale ",
      category: "Privato"
  },
  {
      label: "Dirigente Organizzazione Del Personale",
      value: "Dirigente Organizzazione Del Personale",
      category: "Privato"
  },
  {
      label: "Dirigente Sindacale",
      value: "Dirigente Sindacale",
      category: "Privato"
  },
  {
      label: "Dirigente Sistemi Informativi",
      value: "Dirigente Sistemi Informativi",
      category: "Privato"
  },
  {
      label: "Dirigente Tecnico",
      value: "Dirigente Tecnico",
      category: "Privato"
  },
  {
      label: "Distribution & Forecast Planning Director",
      value: "Distribution & Forecast Planning Director",
      category: "Privato"
  },
  {
      label: "District Director",
      value: "District Director",
      category: "Privato"
  },
  {
      label: "District Sales Manager",
      value: "District Sales Manager",
      category: "Privato"
  },
  {
      label: "Division Manager",
      value: "Division Manager",
      category: "Privato"
  },
  {
      label: "Divisional Business Senior Manager ",
      value: "Divisional Business Senior Manager ",
      category: "Privato"
  },
  {
      label: "Docente",
      value: "Docente",
      category: "Privato"
  },
  {
      label: "Dpo",
      value: "Dpo",
      category: "Privato"
  },
  {
      label: "Drug Substance Development Department Manager",
      value: "Drug Substance Development Department Manager",
      category: "Privato"
  },
  {
      label: "E-commerce Manager",
      value: "E-commerce Manager",
      category: "Privato"
  },
  {
      label: "E-commerce Specialist",
      value: "E-commerce Specialist",
      category: "Privato"
  },
  {
      label: "Ecosystem Lead Dermatology",
      value: "Ecosystem Lead Dermatology",
      category: "Privato"
  },
  {
      label: "Editor",
      value: "Editor",
      category: "Privato"
  },
  {
      label: "Ege & Energy Manager Ufficio Tecnico",
      value: "Ege & Energy Manager Ufficio Tecnico",
      category: "Privato"
  },
  {
      label: "Ehs Senior Manager",
      value: "Ehs Senior Manager",
      category: "Privato"
  },
  {
      label: "El Sales Operations Manager",
      value: "El Sales Operations Manager",
      category: "Privato"
  },
  {
      label: "E-Learning Specialist",
      value: "E-Learning Specialist",
      category: "Privato"
  },
  {
      label: "Employee",
      value: "Employee",
      category: "Privato/Pubblico"
  },
  {
      label: "Energy Data Specialist ",
      value: "Energy Data Specialist ",
      category: "Privato"
  },
  {
      label: "Energy Deputy Manager, Italy ",
      value: "Energy Deputy Manager, Italy ",
      category: "Privato"
  },
  {
      label: "Energy Engineer",
      value: "Energy Engineer",
      category: "Privato"
  },
  {
      label: "Energy Manager",
      value: "Energy Manager",
      category: "Privato/Pubblico"
  },
  {
      label: "Energy Manager & Environmental Specialist ",
      value: "Energy Manager & Environmental Specialist ",
      category: "Privato"
  },
  {
      label: "Energy Specialist",
      value: "Energy Specialist",
      category: "Privato"
  },
  {
      label: "Engagement Manager",
      value: "Engagement Manager",
      category: "Privato"
  },
  {
      label: "Engine Application Project Manager",
      value: "Engine Application Project Manager",
      category: "Privato"
  },
  {
      label: "Engineer",
      value: "Engineer",
      category: "Privato"
  },
  {
      label: "Engineering Director",
      value: "Engineering Director",
      category: "Privato"
  },
  {
      label: "Engineering Manager",
      value: "Engineering Manager",
      category: "Privato"
  },
  {
      label: "Enteprise Sales Manager",
      value: "Enteprise Sales Manager",
      category: "Privato"
  },
  {
      label: "Enterprise Account",
      value: "Enterprise Account",
      category: "Privato"
  },
  {
      label: "Enterprise Account Executive",
      value: "Enterprise Account Executive",
      category: "Privato"
  },
  {
      label: "Enterprise Architect",
      value: "Enterprise Architect",
      category: "Privato"
  },
  {
      label: "Enterprise Marketing Manager",
      value: "Enterprise Marketing Manager",
      category: "Privato"
  },
  {
      label: "Enterprise Sales Director",
      value: "Enterprise Sales Director",
      category: "Privato"
  },
  {
      label: "Enterprise Sales Manager",
      value: "Enterprise Sales Manager",
      category: "Privato"
  },
  {
      label: "Enterprise Security Executive",
      value: "Enterprise Security Executive",
      category: "Privato"
  },
  {
      label: "Environmental & Property Protection Manager",
      value: "Environmental & Property Protection Manager",
      category: "Privato"
  },
  {
      label: "Environmental Specialist ",
      value: "Environmental Specialist ",
      category: "Privato"
  },
  {
      label: "Equity Partner",
      value: "Equity Partner",
      category: "Privato"
  },
  {
      label: "ERP Specialist",
      value: "ERP Specialist",
      category: "Privato"
  },
  {
      label: "ESG Manager",
      value: "ESG Manager",
      category: "Privato"
  },
  {
      label: "Esperta Business Intelligence",
      value: "Esperta Business Intelligence",
      category: "Privato"
  },
  {
      label: "Esperta Comunicazione Interna",
      value: "Esperta Comunicazione Interna",
      category: "Privato"
  },
  {
      label: "Esperta Divisione Assetti E Sviluppo Organizzativo",
      value: "Esperta Divisione Assetti E Sviluppo Organizzativo",
      category: "Privato"
  },
  {
      label: "Esperto Gestione Energia",
      value: "Esperto Gestione Energia",
      category: "Privato"
  },
  {
      label: "Event Content Manager",
      value: "Event Content Manager",
      category: "Privato"
  },
  {
      label: "Event Coordinator",
      value: "Event Coordinator",
      category: "Privato"
  },
  {
      label: "Event Manager",
      value: "Event Manager",
      category: "Privato"
  },
  {
      label: "Event Officer",
      value: "Event Officer",
      category: "Privato"
  },
  {
      label: "Executive",
      value: "Executive",
      category: "Privato"
  },
  {
      label: "Executive Assistant",
      value: "Executive Assistant",
      category: "Privato"
  },
  {
      label: "Executive Board Member",
      value: "Executive Board Member",
      category: "Privato"
  },
  {
      label: "Executive Development Advisor",
      value: "Executive Development Advisor",
      category: "Privato"
  },
  {
      label: "Executive Director",
      value: "Executive Director",
      category: "Privato"
  },
  {
      label: "Executive Education",
      value: "Executive Education",
      category: "Privato"
  },
  {
      label: "Executive Manager",
      value: "Executive Manager",
      category: "Privato"
  },
  {
      label: "Executive Partner",
      value: "Executive Partner",
      category: "Privato"
  },
  {
      label: "Executive Relationship Manager",
      value: "Executive Relationship Manager",
      category: "Privato"
  },
  {
      label: "Executive Vice President&Ceo",
      value: "Executive Vice President&Ceo",
      category: "Privato"
  },
  {
      label: "Executive Vp Strategy & Innovation ",
      value: "Executive Vp Strategy & Innovation ",
      category: "Privato"
  },
  {
      label: "Export Area Manager",
      value: "Export Area Manager",
      category: "Privato"
  },
  {
      label: "Export Manager",
      value: "Export Manager",
      category: "Privato"
  },
  {
      label: "External Relations Manager",
      value: "External Relations Manager",
      category: "Privato"
  },
  {
      label: "Facilities System Manager",
      value: "Facilities System Manager",
      category: "Privato"
  },
  {
      label: "Facility Coordinator",
      value: "Facility Coordinator",
      category: "Privato"
  },
  {
      label: "Facility Manager",
      value: "Facility Manager",
      category: "Privato"
  },
  {
      label: "Facility Specialist",
      value: "Facility Specialist",
      category: "Privato"
  },
  {
      label: "Field Maintenance",
      value: "Field Maintenance",
      category: "Privato"
  },
  {
      label: "Field Manager",
      value: "Field Manager",
      category: "Privato"
  },
  {
      label: "Field Marketing Manager",
      value: "Field Marketing Manager",
      category: "Privato"
  },
  {
      label: "Field Sales Service Manager ",
      value: "Field Sales Service Manager ",
      category: "Privato"
  },
  {
      label: "Finance Business Partner",
      value: "Finance Business Partner",
      category: "Privato"
  },
  {
      label: "Finance Consultant",
      value: "Finance Consultant",
      category: "Privato"
  },
  {
      label: "Finance Director",
      value: "Finance Director",
      category: "Privato"
  },
  {
      label: "Finance Head",
      value: "Finance Head",
      category: "Privato"
  },
  {
      label: "Finance Manager",
      value: "Finance Manager",
      category: "Privato"
  },
  {
      label: "Finance Specialist",
      value: "Finance Specialist",
      category: "Privato"
  },
  {
      label: "Finance Staff",
      value: "Finance Staff",
      category: "Privato"
  },
  {
      label: "Financial Consultant ",
      value: "Financial Consultant ",
      category: "Privato"
  },
  {
      label: "Financial Director",
      value: "Financial Director",
      category: "Privato"
  },
  {
      label: "Formatore",
      value: "Formatore",
      category: "Privato"
  },
  {
      label: "Fotografo",
      value: "Fotografo",
      category: "Privato"
  },
  {
      label: "Founder",
      value: "Founder",
      category: "Privato"
  },
  {
      label: "Founder & Ceo",
      value: "Founder & Ceo",
      category: "Privato"
  },
  {
      label: "Founder And Managing Director",
      value: "Founder And Managing Director",
      category: "Privato"
  },
  {
      label: "Foundry Manager",
      value: "Foundry Manager",
      category: "Privato"
  },
  {
      label: "Freelance",
      value: "Freelance",
      category: "Privato"
  },
  {
      label: "Funzionario",
      value: "Funzionario",
      category: "Privato/Pubblico"
  },
  {
      label: "Funzionario Acquisti",
      value: "Funzionario Acquisti",
      category: "Privato/Pubblico"
  },
  {
      label: "Funzionario Amministrativo",
      value: "Funzionario Amministrativo",
      category: "Privato/Pubblico"
  },
  {
      label: "Funzionario Commerciale",
      value: "Funzionario Commerciale",
      category: "Privato"
  },
  {
      label: "Funzionario HR",
      value: "Funzionario HR",
      category: "Privato"
  },
  {
      label: "Funzionario IT",
      value: "Funzionario IT",
      category: "Privato"
  },
  {
      label: "Funzionario Legale",
      value: "Funzionario Legale",
      category: "Privato"
  },
  {
      label: "Funzionario Logistica",
      value: "Funzionario Logistica",
      category: "Privato"
  },
  {
      label: "Funzionario Marketing",
      value: "Funzionario Marketing",
      category: "Privato"
  },
  {
      label: "Funzionario Produzione",
      value: "Funzionario Produzione",
      category: "Privato"
  },
  {
      label: "Funzionario R&D",
      value: "Funzionario R&D",
      category: "Privato"
  },
  {
      label: "Funzionario Relazioni Internazionali",
      value: "Funzionario Relazioni Internazionali",
      category: "Privato"
  },
  {
      label: "General Manager",
      value: "General Manager",
      category: "Privato"
  },
  {
      label: "Geologo",
      value: "Geologo",
      category: "Privato"
  },
  {
      label: "Geometra",
      value: "Geometra",
      category: "Privato"
  },
  {
      label: "Giornalista",
      value: "Giornalista",
      category: "Privato"
  },
  {
      label: "Global Account Manager",
      value: "Global Account Manager",
      category: "Privato"
  },
  {
      label: "Global Account Technology Strategist",
      value: "Global Account Technology Strategist",
      category: "Privato"
  },
  {
      label: "Global Business Operations Controlling Manager",
      value: "Global Business Operations Controlling Manager",
      category: "Privato"
  },
  {
      label: "Global Director P&C Management",
      value: "Global Director P&C Management",
      category: "Privato"
  },
  {
      label: "Global Education Manager",
      value: "Global Education Manager",
      category: "Privato"
  },
  {
      label: "Global Head Of Learning ",
      value: "Global Head Of Learning ",
      category: "Privato"
  },
  {
      label: "Global Head Of Supplier Relations And Performance Management",
      value: "Global Head Of Supplier Relations And Performance Management",
      category: "Privato"
  },
  {
      label: "Global Head Of Trade Finance",
      value: "Global Head Of Trade Finance",
      category: "Privato"
  },
  {
      label: "Global Hr",
      value: "Global Hr",
      category: "Privato"
  },
  {
      label: "Global Leadershhip Development Lead",
      value: "Global Leadershhip Development Lead",
      category: "Privato"
  },
  {
      label: "Global Marketing Director",
      value: "Global Marketing Director",
      category: "Privato"
  },
  {
      label: "Global Medical Marketing & Sales Training Director  ",
      value: "Global Medical Marketing & Sales Training Director  ",
      category: "Privato"
  },
  {
      label: "Global Product Management Lead",
      value: "Global Product Management Lead",
      category: "Privato"
  },
  {
      label: "Global Product Marketing",
      value: "Global Product Marketing",
      category: "Privato"
  },
  {
      label: "Global Relationship Manager",
      value: "Global Relationship Manager",
      category: "Privato"
  },
  {
      label: "Global Sales Director",
      value: "Global Sales Director",
      category: "Privato"
  },
  {
      label: "Governamental & Institutional Affairs",
      value: "Governamental & Institutional Affairs",
      category: "Privato"
  },
  {
      label: "Grafico",
      value: "Grafico",
      category: "Privato"
  },
  {
      label: "Graphic Designer",
      value: "Graphic Designer",
      category: "Privato"
  },
  {
      label: "Group Administration Director",
      value: "Group Administration Director",
      category: "Privato"
  },
  {
      label: "Group Energy Leader",
      value: "Group Energy Leader",
      category: "Privato"
  },
  {
      label: "Group Executive Development",
      value: "Group Executive Development",
      category: "Privato"
  },
  {
      label: "Group Finance",
      value: "Group Finance",
      category: "Privato"
  },
  {
      label: "Group Manager ",
      value: "Group Manager ",
      category: "Privato"
  },
  {
      label: "Group Product Manager Prestige",
      value: "Group Product Manager Prestige",
      category: "Privato"
  },
  {
      label: "Group Quality Director",
      value: "Group Quality Director",
      category: "Privato"
  },
  {
      label: "Growth Hub Head",
      value: "Growth Hub Head",
      category: "Privato"
  },
  {
      label: "Head Accounting, Finance And Control",
      value: "Head Accounting, Finance And Control",
      category: "Privato"
  },
  {
      label: "Head Business Strategy & Partnership",
      value: "Head Business Strategy & Partnership",
      category: "Privato"
  },
  {
      label: "Head Events&Research ",
      value: "Head Events&Research ",
      category: "Privato"
  },
  {
      label: "Head Marketing & Distribution",
      value: "Head Marketing & Distribution",
      category: "Privato"
  },
  {
      label: "Head Of Account Manager Team - Settlement And Processing",
      value: "Head Of Account Manager Team - Settlement And Processing",
      category: "Privato"
  },
  {
      label: "Head Of Architecture & Technology",
      value: "Head Of Architecture & Technology",
      category: "Privato"
  },
  {
      label: "Head Of Assessment Architecture & People Metrics, Associate Partner",
      value: "Head Of Assessment Architecture & People Metrics, Associate Partner",
      category: "Privato"
  },
  {
      label: "Head Of Brand And Communication",
      value: "Head Of Brand And Communication",
      category: "Privato"
  },
  {
      label: "Head Of Business Communication And Customer Direction",
      value: "Head Of Business Communication And Customer Direction",
      category: "Privato"
  },
  {
      label: "Head Of Business Controlling",
      value: "Head Of Business Controlling",
      category: "Privato"
  },
  {
      label: "Head Of Business Development",
      value: "Head Of Business Development",
      category: "Privato"
  },
  {
      label: "Head Of Business Innovation & Marketing",
      value: "Head Of Business Innovation & Marketing",
      category: "Privato"
  },
  {
      label: "Head Of Business Travel Logist. Formaz. & Eventi",
      value: "Head Of Business Travel Logist. Formaz. & Eventi",
      category: "Privato"
  },
  {
      label: "Head Of Center North Area",
      value: "Head Of Center North Area",
      category: "Privato"
  },
  {
      label: "Head Of Central Government",
      value: "Head Of Central Government",
      category: "Privato"
  },
  {
      label: "Head Of Ceo Office & Support",
      value: "Head Of Ceo Office & Support",
      category: "Privato"
  },
  {
      label: "Head Of Claims",
      value: "Head Of Claims",
      category: "Privato"
  },
  {
      label: "Head Of Compliance",
      value: "Head Of Compliance",
      category: "Privato"
  },
  {
      label: "Head Of Continuous Improvement & Mro Senior Manager",
      value: "Head Of Continuous Improvement & Mro Senior Manager",
      category: "Privato"
  },
  {
      label: "Head Of Controlling",
      value: "Head Of Controlling",
      category: "Privato"
  },
  {
      label: "Head Of Corporate Affairs ",
      value: "Head Of Corporate Affairs ",
      category: "Privato"
  },
  {
      label: "Head Of Corporate Training ",
      value: "Head Of Corporate Training ",
      category: "Privato"
  },
  {
      label: "Head Of Corpporate Communication",
      value: "Head Of Corpporate Communication",
      category: "Privato"
  },
  {
      label: "Head Of Country Digitalization",
      value: "Head Of Country Digitalization",
      category: "Privato"
  },
  {
      label: "Head Of Credit Booking",
      value: "Head Of Credit Booking",
      category: "Privato"
  },
  {
      label: "Head Of Department",
      value: "Head Of Department",
      category: "Privato"
  },
  {
      label: "Head Of Digital",
      value: "Head Of Digital",
      category: "Privato"
  },
  {
      label: "Head Of Digital Marketing&Growth ",
      value: "Head Of Digital Marketing&Growth ",
      category: "Privato"
  },
  {
      label: "Head Of Digital People & Culture",
      value: "Head Of Digital People & Culture",
      category: "Privato"
  },
  {
      label: "Head Of Distribution",
      value: "Head Of Distribution",
      category: "Privato"
  },
  {
      label: "Head Of Diversity & Inclusion, Learning And People Engagement ",
      value: "Head Of Diversity & Inclusion, Learning And People Engagement ",
      category: "Privato"
  },
  {
      label: "Head Of Ehs & Operations Sustainability",
      value: "Head Of Ehs & Operations Sustainability",
      category: "Privato"
  },
  {
      label: "Head Of Embrace Field Team",
      value: "Head Of Embrace Field Team",
      category: "Privato"
  },
  {
      label: "Head Of Enterprise & Ict",
      value: "Head Of Enterprise & Ict",
      category: "Privato"
  },
  {
      label: "Head Of Environmental Managment System ",
      value: "Head Of Environmental Managment System ",
      category: "Privato"
  },
  {
      label: "Head Of Ethics & Business Integrity",
      value: "Head Of Ethics & Business Integrity",
      category: "Privato"
  },
  {
      label: "Head Of Europe Road & Extranetwork ",
      value: "Head Of Europe Road & Extranetwork ",
      category: "Privato"
  },
  {
      label: "Head Of Executive Education",
      value: "Head Of Executive Education",
      category: "Privato"
  },
  {
      label: "Head Of Finance Administration ",
      value: "Head Of Finance Administration ",
      category: "Privato"
  },
  {
      label: "Head Of Finance And Controlling",
      value: "Head Of Finance And Controlling",
      category: "Privato"
  },
  {
      label: "Head Of Finance Controlling",
      value: "Head Of Finance Controlling",
      category: "Privato"
  },
  {
      label: "Head Of Financial Planning Analysis & Finance Bp",
      value: "Head Of Financial Planning Analysis & Finance Bp",
      category: "Privato"
  },
  {
      label: "Head Of Fp&A & Digital Finance",
      value: "Head Of Fp&A & Digital Finance",
      category: "Privato"
  },
  {
      label: "Head Of General Accounting ",
      value: "Head Of General Accounting ",
      category: "Privato"
  },
  {
      label: "Head Of Group Executive Development",
      value: "Head Of Group Executive Development",
      category: "Privato"
  },
  {
      label: "Head Of Group Re Portfolio & Transactions",
      value: "Head Of Group Re Portfolio & Transactions",
      category: "Privato"
  },
  {
      label: "Head Of Group Tresury",
      value: "Head Of Group Tresury",
      category: "Privato"
  },
  {
      label: "Head Of Growth",
      value: "Head Of Growth",
      category: "Privato"
  },
  {
      label: "Head Of Growth Marketing",
      value: "Head Of Growth Marketing",
      category: "Privato"
  },
  {
      label: "Head Of Healthcare Market",
      value: "Head Of Healthcare Market",
      category: "Privato"
  },
  {
      label: "Head Of Hr & General Affairs",
      value: "Head Of Hr & General Affairs",
      category: "Privato"
  },
  {
      label: "Head Of Hr & General Service",
      value: "Head Of Hr & General Service",
      category: "Privato"
  },
  {
      label: "Head Of Human Resources ",
      value: "Head Of Human Resources ",
      category: "Privato"
  },
  {
      label: "Head Of Ict",
      value: "Head Of Ict",
      category: "Privato"
  },
  {
      label: "Head Of Indirect Purchasing",
      value: "Head Of Indirect Purchasing",
      category: "Privato"
  },
  {
      label: "Head Of Innovation",
      value: "Head Of Innovation",
      category: "Privato"
  },
  {
      label: "Head Of Legal",
      value: "Head Of Legal",
      category: "Privato"
  },
  {
      label: "Head Of Manufacturing Engineering",
      value: "Head Of Manufacturing Engineering",
      category: "Privato"
  },
  {
      label: "Head Of Marketing",
      value: "Head Of Marketing",
      category: "Privato"
  },
  {
      label: "Head Of Marketing & Professional Service",
      value: "Head Of Marketing & Professional Service",
      category: "Privato"
  },
  {
      label: "Head Of Marketing Analytics",
      value: "Head Of Marketing Analytics",
      category: "Privato"
  },
  {
      label: "Head Of National Sales & Account",
      value: "Head Of National Sales & Account",
      category: "Privato"
  },
  {
      label: "Head Of Negotiation & Broadcast",
      value: "Head Of Negotiation & Broadcast",
      category: "Privato"
  },
  {
      label: "Head Of Neurology Solutions",
      value: "Head Of Neurology Solutions",
      category: "Privato"
  },
  {
      label: "Head Of Office",
      value: "Head Of Office",
      category: "Privato"
  },
  {
      label: "Head Of Organization & Hr Management",
      value: "Head Of Organization & Hr Management",
      category: "Privato"
  },
  {
      label: "Head Of Origination & Business Development",
      value: "Head Of Origination & Business Development",
      category: "Privato"
  },
  {
      label: "Head Of Partnership",
      value: "Head Of Partnership",
      category: "Privato"
  },
  {
      label: "Head Of People And Culture",
      value: "Head Of People And Culture",
      category: "Privato"
  },
  {
      label: "Head Of People Empowerment",
      value: "Head Of People Empowerment",
      category: "Privato"
  },
  {
      label: "Head Of Pr And Corporate Communication",
      value: "Head Of Pr And Corporate Communication",
      category: "Privato"
  },
  {
      label: "Head Of Presales, Opportunities & Alliance",
      value: "Head Of Presales, Opportunities & Alliance",
      category: "Privato"
  },
  {
      label: "Head Of Process Innovation",
      value: "Head Of Process Innovation",
      category: "Privato"
  },
  {
      label: "Head Of Process Quality (Electronics)",
      value: "Head Of Process Quality (Electronics)",
      category: "Privato"
  },
  {
      label: "Head Of Product",
      value: "Head Of Product",
      category: "Privato"
  },
  {
      label: "Head Of Product Development Manager",
      value: "Head Of Product Development Manager",
      category: "Privato"
  },
  {
      label: "Head Of Product Manager",
      value: "Head Of Product Manager",
      category: "Privato"
  },
  {
      label: "Head Of Public Affairs, Italy ",
      value: "Head Of Public Affairs, Italy ",
      category: "Privato"
  },
  {
      label: "Head Of Public Sector",
      value: "Head Of Public Sector",
      category: "Privato"
  },
  {
      label: "Head Of Public Sector Framework Agreement",
      value: "Head Of Public Sector Framework Agreement",
      category: "Privato"
  },
  {
      label: "Head Of R&D",
      value: "Head Of R&D",
      category: "Privato"
  },
  {
      label: "Head Of Sales",
      value: "Head Of Sales",
      category: "Privato"
  },
  {
      label: "Head Of Sales, Public Sector And Government",
      value: "Head Of Sales, Public Sector And Government",
      category: "Privato"
  },
  {
      label: "Head Of Seo",
      value: "Head Of Seo",
      category: "Privato"
  },
  {
      label: "Head Of Space & Satellite",
      value: "Head Of Space & Satellite",
      category: "Privato"
  },
  {
      label: "Head Of Supply Chain Quality",
      value: "Head Of Supply Chain Quality",
      category: "Privato"
  },
  {
      label: "Head Of Sustainability",
      value: "Head Of Sustainability",
      category: "Privato"
  },
  {
      label: "Head Of Talent Center Of Expertise ",
      value: "Head Of Talent Center Of Expertise ",
      category: "Privato"
  },
  {
      label: "Head Of Talent Strategy & Employer Branding, Associate Partner",
      value: "Head Of Talent Strategy & Employer Branding, Associate Partner",
      category: "Privato"
  },
  {
      label: "Head Of Technology Development",
      value: "Head Of Technology Development",
      category: "Privato"
  },
  {
      label: "Head Of The People And Community Department",
      value: "Head Of The People And Community Department",
      category: "Privato"
  },
  {
      label: "Head Pf Organisational Design & Quality",
      value: "Head Pf Organisational Design & Quality",
      category: "Privato"
  },
  {
      label: "Head School Of Managment ",
      value: "Head School Of Managment ",
      category: "Privato"
  },
  {
      label: "Heating Key Account - Air Solutions",
      value: "Heating Key Account - Air Solutions",
      category: "Privato"
  },
  {
      label: "Help Desk",
      value: "Help Desk",
      category: "Privato"
  },
  {
      label: "Hr & Organization Director",
      value: "Hr & Organization Director",
      category: "Privato"
  },
  {
      label: "Hr Business Partner",
      value: "Hr Business Partner",
      category: "Privato"
  },
  {
      label: "Hr Digital Training Specialist",
      value: "Hr Digital Training Specialist",
      category: "Privato"
  },
  {
      label: "Hr Director",
      value: "Hr Director",
      category: "Privato"
  },
  {
      label: "Hr Innovation Manager",
      value: "Hr Innovation Manager",
      category: "Privato"
  },
  {
      label: "Hr Leadership Development & Training ",
      value: "Hr Leadership Development & Training ",
      category: "Privato"
  },
  {
      label: "Hr Learning & Development Senior Manager ",
      value: "Hr Learning & Development Senior Manager ",
      category: "Privato"
  },
  {
      label: "Hr Management Director",
      value: "Hr Management Director",
      category: "Privato"
  },
  {
      label: "HR Manager",
      value: "HR Manager",
      category: "Privato"
  },
  {
      label: "Hr Operations & Compensation Senior Manager",
      value: "Hr Operations & Compensation Senior Manager",
      category: "Privato"
  },
  {
      label: "Hr Plant Manager",
      value: "Hr Plant Manager",
      category: "Privato"
  },
  {
      label: "Hr Project & Training Manager",
      value: "Hr Project & Training Manager",
      category: "Privato"
  },
  {
      label: "Hr Resources Senior Director",
      value: "Hr Resources Senior Director",
      category: "Privato"
  },
  {
      label: "HR Specialist",
      value: "HR Specialist",
      category: "Privato"
  },
  {
      label: "HR Staff",
      value: "HR Staff",
      category: "Privato"
  },
  {
      label: "Hr Staff Management ",
      value: "Hr Staff Management ",
      category: "Privato"
  },
  {
      label: "Hr Staff Manager ",
      value: "Hr Staff Manager ",
      category: "Privato"
  },
  {
      label: "Hr Training & Development",
      value: "Hr Training & Development",
      category: "Privato"
  },
  {
      label: "Hs&P Business Unit Manager",
      value: "Hs&P Business Unit Manager",
      category: "Privato"
  },
  {
      label: "Hse",
      value: "Hse",
      category: "Privato"
  },
  {
      label: "Hse Energy Manager",
      value: "Hse Energy Manager",
      category: "Privato"
  },
  {
      label: "Hse Italy Plants",
      value: "Hse Italy Plants",
      category: "Privato"
  },
  {
      label: "Hse Manager",
      value: "Hse Manager",
      category: "Privato"
  },
  {
      label: "Hse Officer",
      value: "Hse Officer",
      category: "Privato"
  },
  {
      label: "Hse& Energy Manager",
      value: "Hse& Energy Manager",
      category: "Privato"
  },
  {
      label: "Human Capital & Organization Heath Safety",
      value: "Human Capital & Organization Heath Safety",
      category: "Privato"
  },
  {
      label: "Human Capital Solutions Director & Board Member",
      value: "Human Capital Solutions Director & Board Member",
      category: "Privato"
  },
  {
      label: "Human Resources ",
      value: "Human Resources ",
      category: "Privato"
  },
  {
      label: "Human Resources Manager",
      value: "Human Resources Manager",
      category: "Privato"
  },
  {
      label: "Human Resourses Director",
      value: "Human Resourses Director",
      category: "Privato"
  },
  {
      label: "Ict Innovation E Business Consultant",
      value: "Ict Innovation E Business Consultant",
      category: "Privato"
  },
  {
      label: "Ict Manager",
      value: "Ict Manager",
      category: "Privato"
  },
  {
      label: "Ict Officer Manager ",
      value: "Ict Officer Manager ",
      category: "Privato"
  },
  {
      label: "Ict Operations Director",
      value: "Ict Operations Director",
      category: "Privato"
  },
  {
      label: "Impiegato",
      value: "Impiegato",
      category: "Privato/Pubblico"
  },
  {
      label: "Impiegato Amministrativo",
      value: "Impiegato Amministrativo",
      category: "Privato/Pubblico"
  },
  {
      label: "Impiegato Commerciale",
      value: "Impiegato Commerciale",
      category: "Privato"
  },
  {
      label: "Impiegato Direttivo",
      value: "Impiegato Direttivo",
      category: "Privato"
  },
  {
      label: "Imprenditore",
      value: "Imprenditore",
      category: "Privato"
  },
  {
      label: "Inbound Transport Manager",
      value: "Inbound Transport Manager",
      category: "Privato"
  },
  {
      label: "Indirect Senior Buyer",
      value: "Indirect Senior Buyer",
      category: "Privato"
  },
  {
      label: "Industrial Investment Specialist",
      value: "Industrial Investment Specialist",
      category: "Privato"
  },
  {
      label: "Industrial Laundry Service Manager",
      value: "Industrial Laundry Service Manager",
      category: "Privato"
  },
  {
      label: "Industry Leader",
      value: "Industry Leader",
      category: "Privato"
  },
  {
      label: "Industry Solutions Manager",
      value: "Industry Solutions Manager",
      category: "Privato"
  },
  {
      label: "Influencer ",
      value: "Influencer ",
      category: "Privato"
  },
  {
      label: "Infrastructure & Network",
      value: "Infrastructure & Network",
      category: "Privato"
  },
  {
      label: "Infrastructure Architecture Sr Di",
      value: "Infrastructure Architecture Sr Di",
      category: "Privato"
  },
  {
      label: "Infrastructure, It Governance & Cyber Security Specialist",
      value: "Infrastructure, It Governance & Cyber Security Specialist",
      category: "Privato"
  },
  {
      label: "Ingegnere",
      value: "Ingegnere",
      category: "Privato/Pubblico"
  },
  {
      label: "Ingegnere Ambientale",
      value: "Ingegnere Ambientale",
      category: "Privato/Pubblico"
  },
  {
      label: "Ingegnere Civile Project Manager",
      value: "Ingegnere Civile Project Manager",
      category: "Privato/Pubblico"
  },
  {
      label: "Ingegnere Delle Telecomunicazioni",
      value: "Ingegnere Delle Telecomunicazioni",
      category: "Privato"
  },
  {
      label: "Ingegnere Gestionale",
      value: "Ingegnere Gestionale",
      category: "Privato/Pubblico"
  },
  {
      label: "Ingegnere Informatico",
      value: "Ingegnere Informatico",
      category: "Privato/Pubblico"
  },
  {
      label: "Ingegnere Termomeccanico",
      value: "Ingegnere Termomeccanico",
      category: "Privato"
  },
  {
      label: "Ingegneria Dell'Offerta",
      value: "Ingegneria Dell'Offerta",
      category: "Privato"
  },
  {
      label: "Innovation & Business Development",
      value: "Innovation & Business Development",
      category: "Privato"
  },
  {
      label: "Innovation & Customer Experience Director",
      value: "Innovation & Customer Experience Director",
      category: "Privato"
  },
  {
      label: "Innovation & Plant Manager",
      value: "Innovation & Plant Manager",
      category: "Privato"
  },
  {
      label: "Innovation & Transformation Director",
      value: "Innovation & Transformation Director",
      category: "Privato"
  },
  {
      label: "Innovation Director",
      value: "Innovation Director",
      category: "Privato"
  },
  {
      label: "Innovation Manager",
      value: "Innovation Manager",
      category: "Privato"
  },
  {
      label: "Innovation Specialist",
      value: "Innovation Specialist",
      category: "Privato"
  },
  {
      label: "Insegnante",
      value: "Insegnante",
      category: "Privato/Pubblico"
  },
  {
      label: "Institutional Relations Director",
      value: "Institutional Relations Director",
      category: "Privato"
  },
  {
      label: "Internal & Change Communication Manager",
      value: "Internal & Change Communication Manager",
      category: "Privato"
  },
  {
      label: "Internal Communication Global Manager",
      value: "Internal Communication Global Manager",
      category: "Privato"
  },
  {
      label: "International Area Manager ",
      value: "International Area Manager ",
      category: "Privato"
  },
  {
      label: "International D&D Partner",
      value: "International D&D Partner",
      category: "Privato"
  },
  {
      label: "International Hr Director",
      value: "International Hr Director",
      category: "Privato"
  },
  {
      label: "International Marketing Senior Manager",
      value: "International Marketing Senior Manager",
      category: "Privato"
  },
  {
      label: "International Sales Manager",
      value: "International Sales Manager",
      category: "Privato"
  },
  {
      label: "International Sales Specialist",
      value: "International Sales Specialist",
      category: "Privato"
  },
  {
      label: "Investor Relations And Group Reporting Manager",
      value: "Investor Relations And Group Reporting Manager",
      category: "Privato"
  },
  {
      label: "Iot Sales Expert Southern Europe",
      value: "Iot Sales Expert Southern Europe",
      category: "Privato"
  },
  {
      label: "Iot Sales Manager",
      value: "Iot Sales Manager",
      category: "Privato"
  },
  {
      label: "It",
      value: "It",
      category: "Privato"
  },
  {
      label: "It Analyst & Developer",
      value: "It Analyst & Developer",
      category: "Privato"
  },
  {
      label: "It Bus Process Reengineering Manager",
      value: "It Bus Process Reengineering Manager",
      category: "Privato"
  },
  {
      label: "It Demand Manager",
      value: "It Demand Manager",
      category: "Privato"
  },
  {
      label: "It Director",
      value: "It Director",
      category: "Privato"
  },
  {
      label: "IT Engineer",
      value: "IT Engineer",
      category: "Privato"
  },
  {
      label: "IT Governance",
      value: "IT Governance",
      category: "Privato"
  },
  {
      label: "IT Manager",
      value: "IT Manager",
      category: "Privato"
  },
  {
      label: "IT Procurement",
      value: "IT Procurement",
      category: "Privato"
  },
  {
      label: "IT Project Manager",
      value: "IT Project Manager",
      category: "Privato"
  },
  {
      label: "It Recruiter",
      value: "It Recruiter",
      category: "Privato"
  },
  {
      label: "It Security Manager",
      value: "It Security Manager",
      category: "Privato"
  },
  {
      label: "IT Specialist",
      value: "IT Specialist",
      category: "Privato"
  },
  {
      label: "IT Staff",
      value: "IT Staff",
      category: "Privato"
  },
  {
      label: "Italy General Manager",
      value: "Italy General Manager",
      category: "Privato"
  },
  {
      label: "Italy Head Of Finance",
      value: "Italy Head Of Finance",
      category: "Privato"
  },
  {
      label: "Italy Head Of Marketing",
      value: "Italy Head Of Marketing",
      category: "Privato"
  },
  {
      label: "Italy It Governance Senior Director",
      value: "Italy It Governance Senior Director",
      category: "Privato"
  },
  {
      label: "Italy System Operations Director",
      value: "Italy System Operations Director",
      category: "Privato"
  },
  {
      label: "Junior Analyst",
      value: "Junior Analyst",
      category: "Privato"
  },
  {
      label: "Junior Consultant",
      value: "Junior Consultant",
      category: "Privato"
  },
  {
      label: "Junior Event Content Coordinator ",
      value: "Junior Event Content Coordinator ",
      category: "Privato"
  },
  {
      label: "Junior Marketing Specialist",
      value: "Junior Marketing Specialist",
      category: "Privato"
  },
  {
      label: "Junior Project Manager",
      value: "Junior Project Manager",
      category: "Privato"
  },
  {
      label: "Junior Project Officer",
      value: "Junior Project Officer",
      category: "Privato"
  },
  {
      label: "Junior Researcher",
      value: "Junior Researcher",
      category: "Privato"
  },
  {
      label: "Junior Training Project Coordinator",
      value: "Junior Training Project Coordinator",
      category: "Privato"
  },
  {
      label: "Kam",
      value: "Kam",
      category: "Privato"
  },
  {
      label: "Kam Public Sector",
      value: "Kam Public Sector",
      category: "Privato"
  },
  {
      label: "Key Account",
      value: "Key Account",
      category: "Privato"
  },
  {
      label: "Key Account Business Partner ",
      value: "Key Account Business Partner ",
      category: "Privato"
  },
  {
      label: "Key Account Manager",
      value: "Key Account Manager",
      category: "Privato"
  },
  {
      label: "Key Account Manager - Public Sector",
      value: "Key Account Manager - Public Sector",
      category: "Privato"
  },
  {
      label: "Knowledge Transfer Manager",
      value: "Knowledge Transfer Manager",
      category: "Privato"
  },
  {
      label: "Lawyer",
      value: "Lawyer",
      category: "Privato"
  },
  {
      label: "Lead Consultant",
      value: "Lead Consultant",
      category: "Privato"
  },
  {
      label: "Lead It Project Manager",
      value: "Lead It Project Manager",
      category: "Privato"
  },
  {
      label: "Learning & Development And Business Consulting Ass. Director",
      value: "Learning & Development And Business Consulting Ass. Director",
      category: "Privato"
  },
  {
      label: "Learning & Development Manager",
      value: "Learning & Development Manager",
      category: "Privato"
  },
  {
      label: "Learning Analyst",
      value: "Learning Analyst",
      category: "Privato"
  },
  {
      label: "Learning And Development Manager",
      value: "Learning And Development Manager",
      category: "Privato"
  },
  {
      label: "Learning Senior Manager",
      value: "Learning Senior Manager",
      category: "Privato"
  },
  {
      label: "Legal & Compliance Director",
      value: "Legal & Compliance Director",
      category: "Privato"
  },
  {
      label: "Legal & Compliance Manager",
      value: "Legal & Compliance Manager",
      category: "Privato"
  },
  {
      label: "Legal & Compliance Specialist",
      value: "Legal & Compliance Specialist",
      category: "Privato"
  },
  {
      label: "Legal & Compliance Staff",
      value: "Legal & Compliance Staff",
      category: "Privato"
  },
  {
      label: "Legal Consultant",
      value: "Legal Consultant",
      category: "Privato"
  },
  {
      label: "Legal Counsel",
      value: "Legal Counsel",
      category: "Privato"
  },
  {
      label: "Legale",
      value: "Legale",
      category: "Privato"
  },
  {
      label: "Legale Rappresentante",
      value: "Legale Rappresentante",
      category: "Privato"
  },
  {
      label: "Libero Professionista",
      value: "Libero Professionista",
      category: "Privato/Pubblico"
  },
  {
      label: "Logistic",
      value: "Logistic",
      category: "Privato"
  },
  {
      label: "Logistic Director",
      value: "Logistic Director",
      category: "Privato"
  },
  {
      label: "Logistic Project Leader",
      value: "Logistic Project Leader",
      category: "Privato"
  },
  {
      label: "Logistica",
      value: "Logistica",
      category: "Privato"
  },
  {
      label: "Logistics & Final Inspection Manager",
      value: "Logistics & Final Inspection Manager",
      category: "Privato"
  },
  {
      label: "Logistics Manager",
      value: "Logistics Manager",
      category: "Privato"
  },
  {
      label: "Logistics Specialist",
      value: "Logistics Specialist",
      category: "Privato"
  },
  {
      label: "Logistics Staff",
      value: "Logistics Staff",
      category: "Privato"
  },
  {
      label: "Lottery Marketing Senior Manager",
      value: "Lottery Marketing Senior Manager",
      category: "Privato"
  },
  {
      label: "Lottery Product Development Senior Manager",
      value: "Lottery Product Development Senior Manager",
      category: "Privato"
  },
  {
      label: "Macroeconomic Analyst",
      value: "Macroeconomic Analyst",
      category: "Privato"
  },
  {
      label: "Magistrato",
      value: "Magistrato",
      category: "Pubblico"
  },
  {
      label: "Maintenance Manager ",
      value: "Maintenance Manager ",
      category: "Privato"
  },
  {
      label: "Major Account Executive",
      value: "Major Account Executive",
      category: "Privato"
  },
  {
      label: "Managed Services Pm",
      value: "Managed Services Pm",
      category: "Privato"
  },
  {
      label: "Management",
      value: "Management",
      category: "Privato"
  },
  {
      label: "Management Board Assistant & Sustainability Ambassador",
      value: "Management Board Assistant & Sustainability Ambassador",
      category: "Privato"
  },
  {
      label: "Management Consultant",
      value: "Management Consultant",
      category: "Privato"
  },
  {
      label: "Management Consulting Analyst",
      value: "Management Consulting Analyst",
      category: "Privato"
  },
  {
      label: "Management Systems & Test Director",
      value: "Management Systems & Test Director",
      category: "Privato"
  },
  {
      label: "Manager",
      value: "Manager",
      category: "Pubblico"
  },
  {
      label: "Manager Associato",
      value: "Manager Associato",
      category: "Privato"
  },
  {
      label: "Manager Banker Fb Supervisor",
      value: "Manager Banker Fb Supervisor",
      category: "Privato"
  },
  {
      label: "Manager Communication",
      value: "Manager Communication",
      category: "Privato"
  },
  {
      label: "Manager Di Area",
      value: "Manager Di Area",
      category: "Privato"
  },
  {
      label: "Manager Hr",
      value: "Manager Hr",
      category: "Privato"
  },
  {
      label: "Manager Marketing",
      value: "Manager Marketing",
      category: "Privato"
  },
  {
      label: "Manager Of Finance&Administration",
      value: "Manager Of Finance&Administration",
      category: "Privato"
  },
  {
      label: "Manager Of Kam",
      value: "Manager Of Kam",
      category: "Privato"
  },
  {
      label: "Manager Of Sales",
      value: "Manager Of Sales",
      category: "Privato"
  },
  {
      label: "Manager Of  Plant Engineering",
      value: "Manager Of  Plant Engineering",
      category: "Privato"
  },
  {
      label: "Manager People Management",
      value: "Manager People Management",
      category: "Privato"
  },
  {
      label: "Manager Public Grants Management",
      value: "Manager Public Grants Management",
      category: "Privato"
  },
  {
      label: "Manager R&Cm, Pricing & Customer Fulfillment",
      value: "Manager R&Cm, Pricing & Customer Fulfillment",
      category: "Privato"
  },
  {
      label: "Manager Solution Consulting",
      value: "Manager Solution Consulting",
      category: "Privato"
  },
  {
      label: "Manager, Financial Reporting And Accounting",
      value: "Manager, Financial Reporting And Accounting",
      category: "Privato"
  },
  {
      label: "Manager, Partner Development",
      value: "Manager, Partner Development",
      category: "Privato"
  },
  {
      label: "Manager, Trade&Investment ",
      value: "Manager, Trade&Investment ",
      category: "Privato"
  },
  {
      label: "Managing Consultant",
      value: "Managing Consultant",
      category: "Privato"
  },
  {
      label: "Managing Director",
      value: "Managing Director",
      category: "Privato"
  },
  {
      label: "Managing Director & Senior Cybersecurity Advisor",
      value: "Managing Director & Senior Cybersecurity Advisor",
      category: "Privato"
  },
  {
      label: "Managing Director And Board Member",
      value: "Managing Director And Board Member",
      category: "Privato"
  },
  {
      label: "Managing Partner",
      value: "Managing Partner",
      category: "Privato"
  },
  {
      label: "Manufacturing & Quality It Director",
      value: "Manufacturing & Quality It Director",
      category: "Privato"
  },
  {
      label: "Manufacturing Excellence Director",
      value: "Manufacturing Excellence Director",
      category: "Privato"
  },
  {
      label: "Manufacturing Manager",
      value: "Manufacturing Manager",
      category: "Privato"
  },
  {
      label: "Manufacturing Site Quality Leader",
      value: "Manufacturing Site Quality Leader",
      category: "Privato"
  },
  {
      label: "Manutenzione",
      value: "Manutenzione",
      category: "Privato/Pubblico"
  },
  {
      label: "Market Access & Pricing Director",
      value: "Market Access & Pricing Director",
      category: "Privato"
  },
  {
      label: "Market Access Country Head",
      value: "Market Access Country Head",
      category: "Privato"
  },
  {
      label: "Market Access Director",
      value: "Market Access Director",
      category: "Privato"
  },
  {
      label: "Market Analyst",
      value: "Market Analyst",
      category: "Privato"
  },
  {
      label: "Market Development Manager Structural Heart",
      value: "Market Development Manager Structural Heart",
      category: "Privato"
  },
  {
      label: "Market Line Manager",
      value: "Market Line Manager",
      category: "Privato"
  },
  {
      label: "Market Manager",
      value: "Market Manager",
      category: "Privato"
  },
  {
      label: "Marketing & Communication",
      value: "Marketing & Communication",
      category: "Privato"
  },
  {
      label: "Marketing & Communication Manager",
      value: "Marketing & Communication Manager",
      category: "Privato"
  },
  {
      label: "Marketing & Education Manager",
      value: "Marketing & Education Manager",
      category: "Privato"
  },
  {
      label: "Marketing & Innovation",
      value: "Marketing & Innovation",
      category: "Privato"
  },
  {
      label: "Marketing & Sales",
      value: "Marketing & Sales",
      category: "Privato"
  },
  {
      label: "Marketing & Sales Manager",
      value: "Marketing & Sales Manager",
      category: "Privato"
  },
  {
      label: "Marketing Access Director",
      value: "Marketing Access Director",
      category: "Privato"
  },
  {
      label: "Marketing Analyst",
      value: "Marketing Analyst",
      category: "Privato"
  },
  {
      label: "Marketing And Communication Program Manager ",
      value: "Marketing And Communication Program Manager ",
      category: "Privato"
  },
  {
      label: "Marketing Commerciale",
      value: "Marketing Commerciale",
      category: "Privato"
  },
  {
      label: "Marketing Communication Director",
      value: "Marketing Communication Director",
      category: "Privato"
  },
  {
      label: "Marketing Communication Manager",
      value: "Marketing Communication Manager",
      category: "Privato"
  },
  {
      label: "Marketing Director",
      value: "Marketing Director",
      category: "Privato"
  },
  {
      label: "Marketing Event Manager ",
      value: "Marketing Event Manager ",
      category: "Privato"
  },
  {
      label: "Marketing Executive",
      value: "Marketing Executive",
      category: "Privato"
  },
  {
      label: "Marketing Leader & Digital Health, Italy & Greece",
      value: "Marketing Leader & Digital Health, Italy & Greece",
      category: "Privato"
  },
  {
      label: "Marketing Manager",
      value: "Marketing Manager",
      category: "Privato"
  },
  {
      label: "Marketing Manager Immunology",
      value: "Marketing Manager Immunology",
      category: "Privato"
  },
  {
      label: "Marketing Manager Italia",
      value: "Marketing Manager Italia",
      category: "Privato"
  },
  {
      label: "Marketing Manager Neuroscience",
      value: "Marketing Manager Neuroscience",
      category: "Privato"
  },
  {
      label: "Marketing Project Manager",
      value: "Marketing Project Manager",
      category: "Privato"
  },
  {
      label: "Marketing Specialist",
      value: "Marketing Specialist",
      category: "Privato"
  },
  {
      label: "Marketing Staff",
      value: "Marketing Staff",
      category: "Privato"
  },
  {
      label: "Marketing Strategico",
      value: "Marketing Strategico",
      category: "Privato"
  },
  {
      label: "Marketing Supervisor",
      value: "Marketing Supervisor",
      category: "Privato"
  },
  {
      label: "Marketing Technology Director",
      value: "Marketing Technology Director",
      category: "Privato"
  },
  {
      label: "Marketing&Commincation Associate, Italy ",
      value: "Marketing&Commincation Associate, Italy ",
      category: "Privato"
  },
  {
      label: "Marketing&Communication Manager",
      value: "Marketing&Communication Manager",
      category: "Privato"
  },
  {
      label: "Media Manager",
      value: "Media Manager",
      category: "Privato"
  },
  {
      label: "Media Producer",
      value: "Media Producer",
      category: "Privato"
  },
  {
      label: "Media Relations Manager",
      value: "Media Relations Manager",
      category: "Privato"
  },
  {
      label: "Media Relations Specialist",
      value: "Media Relations Specialist",
      category: "Privato"
  },
  {
      label: "Medical Department Director",
      value: "Medical Department Director",
      category: "Privato"
  },
  {
      label: "Medical Director",
      value: "Medical Director",
      category: "Privato"
  },
  {
      label: "Medical Lead - Dermatology",
      value: "Medical Lead - Dermatology",
      category: "Privato"
  },
  {
      label: "Medical Management Director",
      value: "Medical Management Director",
      category: "Privato"
  },
  {
      label: "Medical Training & Regulatory Affairs Director",
      value: "Medical Training & Regulatory Affairs Director",
      category: "Privato"
  },
  {
      label: "Medico",
      value: "Medico",
      category: "Privato/Pubblico"
  },
  {
      label: "Member Board Of Directors",
      value: "Member Board Of Directors",
      category: "Privato"
  },
  {
      label: "Member Of Direction",
      value: "Member Of Direction",
      category: "Privato"
  },
  {
      label: "Member Of The Board Manager",
      value: "Member Of The Board Manager",
      category: "Privato"
  },
  {
      label: "Membro Del Consiglio Direttivo",
      value: "Membro Del Consiglio Direttivo",
      category: "Privato"
  },
  {
      label: "Merchandise Planningand Supply Chain Director",
      value: "Merchandise Planningand Supply Chain Director",
      category: "Privato"
  },
  {
      label: "Middle Manager",
      value: "Middle Manager",
      category: "Privato"
  },
  {
      label: "Mobility Manager",
      value: "Mobility Manager",
      category: "Privato"
  },
  {
      label: "Mts & Strategic Client Manager ",
      value: "Mts & Strategic Client Manager ",
      category: "Privato"
  },
  {
      label: "Musicista",
      value: "Musicista",
      category: "Privato"
  },
  {
      label: "National Account Executive",
      value: "National Account Executive",
      category: "Privato"
  },
  {
      label: "National Account Manager",
      value: "National Account Manager",
      category: "Privato"
  },
  {
      label: "National Equipment Manager",
      value: "National Equipment Manager",
      category: "Privato"
  },
  {
      label: "Natural Hazards & Climate Change Risk Consulting Manager ",
      value: "Natural Hazards & Climate Change Risk Consulting Manager ",
      category: "Privato"
  },
  {
      label: "Network Administrator",
      value: "Network Administrator",
      category: "Privato"
  },
  {
      label: "Network Development Director",
      value: "Network Development Director",
      category: "Privato"
  },
  {
      label: "Network Manager",
      value: "Network Manager",
      category: "Privato"
  },
  {
      label: "Network Specialist",
      value: "Network Specialist",
      category: "Privato"
  },
  {
      label: "New Product Development Director",
      value: "New Product Development Director",
      category: "Privato"
  },
  {
      label: "Odontoiatra",
      value: "Odontoiatra",
      category: "Privato"
  },
  {
      label: "Office Manager",
      value: "Office Manager",
      category: "Privato"
  },
  {
      label: "Office Services Manager",
      value: "Office Services Manager",
      category: "Privato"
  },
  {
      label: "Office Staff",
      value: "Office Staff",
      category: "Privato"
  },
  {
      label: "Online Innovation & Customer Data Director",
      value: "Online Innovation & Customer Data Director",
      category: "Privato"
  },
  {
      label: "Online Marketing Director",
      value: "Online Marketing Director",
      category: "Privato"
  },
  {
      label: "Open Innovation Manager",
      value: "Open Innovation Manager",
      category: "Privato"
  },
  {
      label: "Operaio",
      value: "Operaio",
      category: "Privato"
  },
  {
      label: "Operation Area Manager ",
      value: "Operation Area Manager ",
      category: "Privato"
  },
  {
      label: "Operation Director",
      value: "Operation Director",
      category: "Privato"
  },
  {
      label: "Operation Excellence Parts Operations",
      value: "Operation Excellence Parts Operations",
      category: "Privato"
  },
  {
      label: "Operational Excellence Manager",
      value: "Operational Excellence Manager",
      category: "Privato"
  },
  {
      label: "Operational Unit Manager",
      value: "Operational Unit Manager",
      category: "Privato"
  },
  {
      label: "Operations & Compliance Department Manager",
      value: "Operations & Compliance Department Manager",
      category: "Privato"
  },
  {
      label: "Operations Director",
      value: "Operations Director",
      category: "Privato"
  },
  {
      label: "Operations Lead, Italy ",
      value: "Operations Lead, Italy ",
      category: "Privato"
  },
  {
      label: "Operations Manager",
      value: "Operations Manager",
      category: "Privato"
  },
  {
      label: "Operations Senior Director",
      value: "Operations Senior Director",
      category: "Privato"
  },
  {
      label: "Operations Specialist",
      value: "Operations Specialist",
      category: "Privato"
  },
  {
      label: "Operations Staff",
      value: "Operations Staff",
      category: "Privato"
  },
  {
      label: "Operatore",
      value: "Operatore",
      category: "Privato/Pubblico"
  },
  {
      label: "Operatore Di Processo Telco",
      value: "Operatore Di Processo Telco",
      category: "Privato"
  },
  {
      label: "Operatore Di Ripresa",
      value: "Operatore Di Ripresa",
      category: "Privato"
  },
  {
      label: "Organization Staff",
      value: "Organization Staff",
      category: "Privato"
  },
  {
      label: "Overseas Laundry Operations Manager",
      value: "Overseas Laundry Operations Manager",
      category: "Privato"
  },
  {
      label: "Owner",
      value: "Owner",
      category: "Privato"
  },
  {
      label: "P&O Manager&Head Of Industrial Relations",
      value: "P&O Manager&Head Of Industrial Relations",
      category: "Privato"
  },
  {
      label: "Partner",
      value: "Partner",
      category: "Privato/Pubblico"
  },
  {
      label: "Partner Associato",
      value: "Partner Associato",
      category: "Privato"
  },
  {
      label: "Partner Development Manager",
      value: "Partner Development Manager",
      category: "Privato"
  },
  {
      label: "Partner Manager",
      value: "Partner Manager",
      category: "Privato"
  },
  {
      label: "Partner Sales Manager",
      value: "Partner Sales Manager",
      category: "Privato"
  },
  {
      label: "Patient Engagement Lead",
      value: "Patient Engagement Lead",
      category: "Privato"
  },
  {
      label: "People And Communication Director",
      value: "People And Communication Director",
      category: "Privato"
  },
  {
      label: "People Development Manager",
      value: "People Development Manager",
      category: "Privato"
  },
  {
      label: "People Director",
      value: "People Director",
      category: "Privato"
  },
  {
      label: "People Manager Italy",
      value: "People Manager Italy",
      category: "Privato"
  },
  {
      label: "Performance Data Manager",
      value: "Performance Data Manager",
      category: "Privato"
  },
  {
      label: "Performance Improvement Manager",
      value: "Performance Improvement Manager",
      category: "Privato"
  },
  {
      label: "Performance Marketing Manager",
      value: "Performance Marketing Manager",
      category: "Privato"
  },
  {
      label: "Pi Comex Lead",
      value: "Pi Comex Lead",
      category: "Privato"
  },
  {
      label: "Pi Marketing Manager",
      value: "Pi Marketing Manager",
      category: "Privato"
  },
  {
      label: "Planning Senior Director",
      value: "Planning Senior Director",
      category: "Privato"
  },
  {
      label: "Plant Based Director",
      value: "Plant Based Director",
      category: "Privato"
  },
  {
      label: "Plant Director",
      value: "Plant Director",
      category: "Privato/Pubblico"
  },
  {
      label: "Plant Energy Engineer",
      value: "Plant Energy Engineer",
      category: "Privato/Pubblico"
  },
  {
      label: "Plant Hse Specialist",
      value: "Plant Hse Specialist",
      category: "Privato/Pubblico"
  },
  {
      label: "Plant Human Resources Manager",
      value: "Plant Human Resources Manager",
      category: "Privato"
  },
  {
      label: "Plant Manager",
      value: "Plant Manager",
      category: "Privato/Pubblico"
  },
  {
      label: "Plant Manager Engine&Driveline",
      value: "Plant Manager Engine&Driveline",
      category: "Privato"
  },
  {
      label: "Plant Quality Manager",
      value: "Plant Quality Manager",
      category: "Privato"
  },
  {
      label: "Pr & Events Manager",
      value: "Pr & Events Manager",
      category: "Privato"
  },
  {
      label: "Pr Consultant",
      value: "Pr Consultant",
      category: "Privato"
  },
  {
      label: "Practice Leader",
      value: "Practice Leader",
      category: "Privato"
  },
  {
      label: "Praticante",
      value: "Praticante",
      category: "Privato"
  },
  {
      label: "Presales Engineer",
      value: "Presales Engineer",
      category: "Privato"
  },
  {
      label: "Presales Manager",
      value: "Presales Manager",
      category: "Privato"
  },
  {
      label: "Presales Manager Offering Engineering",
      value: "Presales Manager Offering Engineering",
      category: "Privato"
  },
  {
      label: "Presales System Engineer",
      value: "Presales System Engineer",
      category: "Privato"
  },
  {
      label: "President",
      value: "President",
      category: "Privato"
  },
  {
      label: "President & Ceo",
      value: "President & Ceo",
      category: "Privato"
  },
  {
      label: "President & Chief Executive Officer",
      value: "President & Chief Executive Officer",
      category: "Privato"
  },
  {
      label: "President Of The Board",
      value: "President Of The Board",
      category: "Privato"
  },
  {
      label: "Presidente",
      value: "Presidente",
      category: "Privato/Pubblico"
  },
  {
      label: "Presidente E Amministratore Delegato",
      value: "Presidente E Amministratore Delegato",
      category: "Privato"
  },
  {
      label: "Primary Care Business Unit",
      value: "Primary Care Business Unit",
      category: "Privato"
  },
  {
      label: "Principal Solution Architect",
      value: "Principal Solution Architect",
      category: "Privato"
  },
  {
      label: "Privacy Manager",
      value: "Privacy Manager",
      category: "Privato"
  },
  {
      label: "Privacy Officer",
      value: "Privacy Officer",
      category: "Privato"
  },
  {
      label: "Privacy Specialist",
      value: "Privacy Specialist",
      category: "Privato"
  },
  {
      label: "Private Banker",
      value: "Private Banker",
      category: "Privato"
  },
  {
      label: "Private Label Director",
      value: "Private Label Director",
      category: "Privato"
  },
  {
      label: "Privato",
      value: "Privato",
      category: "Privato"
  },
  {
      label: "Process Design Manager",
      value: "Process Design Manager",
      category: "Privato"
  },
  {
      label: "Procurement Director ",
      value: "Procurement Director ",
      category: "Privato"
  },
  {
      label: "Procurement Manager",
      value: "Procurement Manager",
      category: "Privato"
  },
  {
      label: "Procurement Specialist",
      value: "Procurement Specialist",
      category: "Privato"
  },
  {
      label: "Procurement Staff",
      value: "Procurement Staff",
      category: "Privato"
  },
  {
      label: "Product & Innovation Manager",
      value: "Product & Innovation Manager",
      category: "Privato"
  },
  {
      label: "Product & Marketing Senior Manager",
      value: "Product & Marketing Senior Manager",
      category: "Privato"
  },
  {
      label: "Product Communication Chairside Manager",
      value: "Product Communication Chairside Manager",
      category: "Privato"
  },
  {
      label: "Product Communication Manager Labside",
      value: "Product Communication Manager Labside",
      category: "Privato"
  },
  {
      label: "Product Development Manager",
      value: "Product Development Manager",
      category: "Privato"
  },
  {
      label: "Product Director",
      value: "Product Director",
      category: "Privato"
  },
  {
      label: "Product Manager",
      value: "Product Manager",
      category: "Privato"
  },
  {
      label: "Product Manager Senior",
      value: "Product Manager Senior",
      category: "Privato"
  },
  {
      label: "Product Marketing",
      value: "Product Marketing",
      category: "Privato"
  },
  {
      label: "Product Marketing Manager",
      value: "Product Marketing Manager",
      category: "Privato"
  },
  {
      label: "Product Marketing Specialist",
      value: "Product Marketing Specialist",
      category: "Privato"
  },
  {
      label: "Product Owner",
      value: "Product Owner",
      category: "Privato"
  },
  {
      label: "Product Quality Manager",
      value: "Product Quality Manager",
      category: "Privato"
  },
  {
      label: "Product Specialist",
      value: "Product Specialist",
      category: "Privato"
  },
  {
      label: "Production E Safety Manager",
      value: "Production E Safety Manager",
      category: "Privato"
  },
  {
      label: "Production Manager",
      value: "Production Manager",
      category: "Privato"
  },
  {
      label: "Production Technical Specialist ",
      value: "Production Technical Specialist ",
      category: "Privato"
  },
  {
      label: "Production Unit Manager",
      value: "Production Unit Manager",
      category: "Privato"
  },
  {
      label: "Products & Services Owner",
      value: "Products & Services Owner",
      category: "Privato"
  },
  {
      label: "Professional Event Manager",
      value: "Professional Event Manager",
      category: "Privato"
  },
  {
      label: "Professionista",
      value: "Professionista",
      category: "Privato"
  },
  {
      label: "Professore",
      value: "Professore",
      category: "Privato/Pubblico"
  },
  {
      label: "Progettista",
      value: "Progettista",
      category: "Privato"
  },
  {
      label: "Progettista Web",
      value: "Progettista Web",
      category: "Privato"
  },
  {
      label: "Program & Product Manager",
      value: "Program & Product Manager",
      category: "Privato"
  },
  {
      label: "Program & Project Manager",
      value: "Program & Project Manager",
      category: "Privato"
  },
  {
      label: "Program Manag & Proc Monit Manager",
      value: "Program Manag & Proc Monit Manager",
      category: "Privato"
  },
  {
      label: "Program Manager",
      value: "Program Manager",
      category: "Privato"
  },
  {
      label: "Programmatore",
      value: "Programmatore",
      category: "Privato"
  },
  {
      label: "Project Controller",
      value: "Project Controller",
      category: "Privato"
  },
  {
      label: "Project Engineer",
      value: "Project Engineer",
      category: "Privato"
  },
  {
      label: "Project Leader",
      value: "Project Leader",
      category: "Privato"
  },
  {
      label: "Project Management",
      value: "Project Management",
      category: "Privato"
  },
  {
      label: "Project Management Engineering Manager",
      value: "Project Management Engineering Manager",
      category: "Privato"
  },
  {
      label: "Project Management Office",
      value: "Project Management Office",
      category: "Privato"
  },
  {
      label: "Project Manager",
      value: "Project Manager",
      category: "Privato"
  },
  {
      label: "Project Managers Director",
      value: "Project Managers Director",
      category: "Privato"
  },
  {
      label: "Project Office",
      value: "Project Office",
      category: "Privato"
  },
  {
      label: "Promotore",
      value: "Promotore",
      category: "Privato"
  },
  {
      label: "Proposal Manager",
      value: "Proposal Manager",
      category: "Privato"
  },
  {
      label: "Proposal Office Manager",
      value: "Proposal Office Manager",
      category: "Privato"
  },
  {
      label: "Proprietario",
      value: "Proprietario",
      category: "Privato"
  },
  {
      label: "Psicoterapeuta",
      value: "Psicoterapeuta",
      category: "Privato"
  },
  {
      label: "Pubblicista",
      value: "Pubblicista",
      category: "Privato"
  },
  {
      label: "Public Administration Consultant",
      value: "Public Administration Consultant",
      category: "Privato"
  },
  {
      label: "Public Administration Director",
      value: "Public Administration Director",
      category: "Privato"
  },
  {
      label: "Public Affairs",
      value: "Public Affairs",
      category: "Privato"
  },
  {
      label: "Public Affairs & Communication Director",
      value: "Public Affairs & Communication Director",
      category: "Privato"
  },
  {
      label: "Public Affairs Manager",
      value: "Public Affairs Manager",
      category: "Privato"
  },
  {
      label: "Public Policy Manager",
      value: "Public Policy Manager",
      category: "Privato"
  },
  {
      label: "Public Policy Specialist",
      value: "Public Policy Specialist",
      category: "Privato"
  },
  {
      label: "Public Relations Manager",
      value: "Public Relations Manager",
      category: "Privato"
  },
  {
      label: "Public Sector Kam",
      value: "Public Sector Kam",
      category: "Privato"
  },
  {
      label: "Public Sector Market Executive",
      value: "Public Sector Market Executive",
      category: "Privato"
  },
  {
      label: "Purchasing Manager ",
      value: "Purchasing Manager ",
      category: "Privato"
  },
  {
      label: "Quadro",
      value: "Quadro",
      category: "Privato"
  },
  {
      label: "Quadro Area Staff",
      value: "Quadro Area Staff",
      category: "Privato"
  },
  {
      label: "Quadro Aziendale",
      value: "Quadro Aziendale",
      category: "Privato"
  },
  {
      label: "Quadro Direttivo",
      value: "Quadro Direttivo",
      category: "Privato"
  },
  {
      label: "Quadro Settore Servizi Previdenziali E Assistenziali",
      value: "Quadro Settore Servizi Previdenziali E Assistenziali",
      category: "Privato"
  },
  {
      label: "Quailty Corporate & Esg Manager ",
      value: "Quailty Corporate & Esg Manager ",
      category: "Privato"
  },
  {
      label: "Qualità E Ambiente",
      value: "Qualità E Ambiente",
      category: "Privato"
  },
  {
      label: "Quality Director",
      value: "Quality Director",
      category: "Privato"
  },
  {
      label: "Quality Manager",
      value: "Quality Manager",
      category: "Privato"
  },
  {
      label: "Quality Officer",
      value: "Quality Officer",
      category: "Privato"
  },
  {
      label: "Quality Specialist",
      value: "Quality Specialist",
      category: "Privato"
  },
  {
      label: "R&D",
      value: "R&D",
      category: "Privato"
  },
  {
      label: "R&D And Quality Director",
      value: "R&D And Quality Director",
      category: "Privato"
  },
  {
      label: "R&D And Technical Department Manager",
      value: "R&D And Technical Department Manager",
      category: "Privato"
  },
  {
      label: "R&D Director",
      value: "R&D Director",
      category: "Privato"
  },
  {
      label: "R&D Hr Business Partner • Global Hr",
      value: "R&D Hr Business Partner • Global Hr",
      category: "Privato"
  },
  {
      label: "R&D Manager",
      value: "R&D Manager",
      category: "Privato"
  },
  {
      label: "R&D Portfolio Managerment Director ",
      value: "R&D Portfolio Managerment Director ",
      category: "Privato"
  },
  {
      label: "R&D Specialist",
      value: "R&D Specialist",
      category: "Privato"
  },
  {
      label: "R&D Staff",
      value: "R&D Staff",
      category: "Privato"
  },
  {
      label: "R&D Team Lead",
      value: "R&D Team Lead",
      category: "Privato"
  },
  {
      label: "R&D Unit Lead",
      value: "R&D Unit Lead",
      category: "Privato"
  },
  {
      label: "Rappresentante Commerciale",
      value: "Rappresentante Commerciale",
      category: "Privato"
  },
  {
      label: "Rappresentate Legale",
      value: "Rappresentate Legale",
      category: "Privato"
  },
  {
      label: "Recruiting & Learning",
      value: "Recruiting & Learning",
      category: "Privato"
  },
  {
      label: "Recruitment Consultant",
      value: "Recruitment Consultant",
      category: "Privato"
  },
  {
      label: "Redattore",
      value: "Redattore",
      category: "Privato"
  },
  {
      label: "Referente Eventi",
      value: "Referente Eventi",
      category: "Privato"
  },
  {
      label: "Referente Politiche Ambiente Energia E Ricerca",
      value: "Referente Politiche Ambiente Energia E Ricerca",
      category: "Privato"
  },
  {
      label: "Referente Vendite",
      value: "Referente Vendite",
      category: "Privato"
  },
  {
      label: "Regional Affairs",
      value: "Regional Affairs",
      category: "Privato"
  },
  {
      label: "Regional Affairs Lead",
      value: "Regional Affairs Lead",
      category: "Privato"
  },
  {
      label: "Regional Compliance Manager",
      value: "Regional Compliance Manager",
      category: "Privato"
  },
  {
      label: "Regional Controller",
      value: "Regional Controller",
      category: "Privato"
  },
  {
      label: "Regional Finance",
      value: "Regional Finance",
      category: "Privato"
  },
  {
      label: "Regional Key Account Manager",
      value: "Regional Key Account Manager",
      category: "Privato"
  },
  {
      label: "Regional Manager",
      value: "Regional Manager",
      category: "Privato"
  },
  {
      label: "Regional Manager & International Project Manager",
      value: "Regional Manager & International Project Manager",
      category: "Privato"
  },
  {
      label: "Regional Public Administration Development Manager",
      value: "Regional Public Administration Development Manager",
      category: "Privato"
  },
  {
      label: "Regional Sales Director",
      value: "Regional Sales Director",
      category: "Privato"
  },
  {
      label: "Regional Sales Manager, Public Sector",
      value: "Regional Sales Manager, Public Sector",
      category: "Privato"
  },
  {
      label: "Regional Vice President",
      value: "Regional Vice President",
      category: "Privato"
  },
  {
      label: "Regional Workplace & Mobility Manager",
      value: "Regional Workplace & Mobility Manager",
      category: "Privato"
  },
  {
      label: "Regulatory & Institutional Affairs Director Italy",
      value: "Regulatory & Institutional Affairs Director Italy",
      category: "Privato"
  },
  {
      label: "Relationship Manager",
      value: "Relationship Manager",
      category: "Privato"
  },
  {
      label: "Relazioni Istituzionali",
      value: "Relazioni Istituzionali",
      category: "Privato"
  },
  {
      label: "Research",
      value: "Research",
      category: "Privato"
  },
  {
      label: "Responsabile Accademy",
      value: "Responsabile Accademy",
      category: "Privato"
  },
  {
      label: "Responsabile Acquisti",
      value: "Responsabile Acquisti",
      category: "Privato"
  },
  {
      label: "Responsabile Amministrativo",
      value: "Responsabile Amministrativo",
      category: "Privato"
  },
  {
      label: "Responsabile Amministrazione",
      value: "Responsabile Amministrazione",
      category: "Privato"
  },
  {
      label: "Responsabile Area Ambiente E Sicurezza",
      value: "Responsabile Area Ambiente E Sicurezza",
      category: "Privato"
  },
  {
      label: "Responsabile Area Appalti Ict",
      value: "Responsabile Area Appalti Ict",
      category: "Privato"
  },
  {
      label: "Responsabile Area Commerciale",
      value: "Responsabile Area Commerciale",
      category: "Privato"
  },
  {
      label: "Responsabile Area Consulenza Del Lavoro",
      value: "Responsabile Area Consulenza Del Lavoro",
      category: "Privato"
  },
  {
      label: "Responsabile Area Di Sviluppo",
      value: "Responsabile Area Di Sviluppo",
      category: "Privato"
  },
  {
      label: "Responsabile Area Diritto E Politiche Ue",
      value: "Responsabile Area Diritto E Politiche Ue",
      category: "Privato"
  },
  {
      label: "Responsabile Area Fiscale",
      value: "Responsabile Area Fiscale",
      category: "Privato"
  },
  {
      label: "Responsabile Area Formazione E Scuola",
      value: "Responsabile Area Formazione E Scuola",
      category: "Privato"
  },
  {
      label: "Responsabile Area It",
      value: "Responsabile Area It",
      category: "Privato"
  },
  {
      label: "Responsabile Aziendale",
      value: "Responsabile Aziendale",
      category: "Privato"
  },
  {
      label: "Responsabile Back Office",
      value: "Responsabile Back Office",
      category: "Privato"
  },
  {
      label: "Responsabile Business Innovation & Development",
      value: "Responsabile Business Innovation & Development",
      category: "Privato"
  },
  {
      label: "Responsabile Business Solution",
      value: "Responsabile Business Solution",
      category: "Privato"
  },
  {
      label: "Responsabile Centri Diretti",
      value: "Responsabile Centri Diretti",
      category: "Privato"
  },
  {
      label: "Responsabile Clienti",
      value: "Responsabile Clienti",
      category: "Privato"
  },
  {
      label: "Responsabile Cloud E Campus Operations",
      value: "Responsabile Cloud E Campus Operations",
      category: "Privato"
  },
  {
      label: "Responsabile Commerciale",
      value: "Responsabile Commerciale",
      category: "Privato"
  },
  {
      label: "Responsabile Commerciale Marketing",
      value: "Responsabile Commerciale Marketing",
      category: "Privato"
  },
  {
      label: "Responsabile Compliance",
      value: "Responsabile Compliance",
      category: "Privato"
  },
  {
      label: "Responsabile Comunicazione",
      value: "Responsabile Comunicazione",
      category: "Privato"
  },
  {
      label: "Responsabile Credit & Partner Care",
      value: "Responsabile Credit & Partner Care",
      category: "Privato"
  },
  {
      label: "Responsabile D'Area",
      value: "Responsabile D'Area",
      category: "Privato"
  },
  {
      label: "Responsabile Database",
      value: "Responsabile Database",
      category: "Privato"
  },
  {
      label: "Responsabile Delivery",
      value: "Responsabile Delivery",
      category: "Privato"
  },
  {
      label: "Responsabile Della Comunicazione",
      value: "Responsabile Della Comunicazione",
      category: "Privato"
  },
  {
      label: "Responsabile Di Direzione",
      value: "Responsabile Di Direzione",
      category: "Privato"
  },
  {
      label: "Responsabile di funzione",
      value: "Responsabile di funzione",
      category: "Privato/Pubblico"
  },
  {
      label: "Responsabile Di Struttura",
      value: "Responsabile Di Struttura",
      category: "Privato"
  },
  {
      label: "Responsabile Digitalizzazione Rete",
      value: "Responsabile Digitalizzazione Rete",
      category: "Privato"
  },
  {
      label: "Responsabile Education",
      value: "Responsabile Education",
      category: "Privato"
  },
  {
      label: "Responsabile Engineering",
      value: "Responsabile Engineering",
      category: "Privato"
  },
  {
      label: "Responsabile Esecutivo",
      value: "Responsabile Esecutivo",
      category: "Privato"
  },
  {
      label: "Responsabile Finanza E Amministrazione",
      value: "Responsabile Finanza E Amministrazione",
      category: "Privato"
  },
  {
      label: "Responsabile Finanziario",
      value: "Responsabile Finanziario",
      category: "Privato"
  },
  {
      label: "Responsabile Formazione",
      value: "Responsabile Formazione",
      category: "Privato"
  },
  {
      label: "Responsabile Formazione E Sviluppo Personale ",
      value: "Responsabile Formazione E Sviluppo Personale ",
      category: "Privato"
  },
  {
      label: "Responsabile Fpa Digital School",
      value: "Responsabile Fpa Digital School",
      category: "Privato"
  },
  {
      label: "Responsabile Governance Officer Public Sector",
      value: "Responsabile Governance Officer Public Sector",
      category: "Privato"
  },
  {
      label: "Responsabile HR",
      value: "Responsabile HR",
      category: "Privato"
  },
  {
      label: "Responsabile Hr E Amministrazione",
      value: "Responsabile Hr E Amministrazione",
      category: "Privato"
  },
  {
      label: "Responsabile Hr Management",
      value: "Responsabile Hr Management",
      category: "Privato"
  },
  {
      label: "Responsabile Impianti",
      value: "Responsabile Impianti",
      category: "Privato"
  },
  {
      label: "Responsabile Ingegneria Degli Impianti",
      value: "Responsabile Ingegneria Degli Impianti",
      category: "Privato"
  },
  {
      label: "Responsabile Innovazione E Trasformazione",
      value: "Responsabile Innovazione E Trasformazione",
      category: "Privato"
  },
  {
      label: "Responsabile Iot",
      value: "Responsabile Iot",
      category: "Privato"
  },
  {
      label: "Responsabile IT",
      value: "Responsabile IT",
      category: "Privato"
  },
  {
      label: "Responsabile Laboratorio",
      value: "Responsabile Laboratorio",
      category: "Privato"
  },
  {
      label: "Responsabile Large Corporate Estero E Finanza Strutturata",
      value: "Responsabile Large Corporate Estero E Finanza Strutturata",
      category: "Privato"
  },
  {
      label: "Responsabile Logistica",
      value: "Responsabile Logistica",
      category: "Privato"
  },
  {
      label: "Responsabile Marketing",
      value: "Responsabile Marketing",
      category: "Privato"
  },
  {
      label: "Responsabile Merchant Services And Solutions Communication",
      value: "Responsabile Merchant Services And Solutions Communication",
      category: "Privato"
  },
  {
      label: "Responsabile Monitoraggio E Valutazione Servizio Civile",
      value: "Responsabile Monitoraggio E Valutazione Servizio Civile",
      category: "Privato"
  },
  {
      label: "Responsabile Nazionale",
      value: "Responsabile Nazionale",
      category: "Privato"
  },
  {
      label: "Responsabile Network",
      value: "Responsabile Network",
      category: "Privato"
  },
  {
      label: "Responsabile Operations",
      value: "Responsabile Operations",
      category: "Privato"
  },
  {
      label: "Responsabile Organizzazione",
      value: "Responsabile Organizzazione",
      category: "Privato"
  },
  {
      label: "Responsabile Pa",
      value: "Responsabile Pa",
      category: "Privato"
  },
  {
      label: "Responsabile Pagamenti E Servizi",
      value: "Responsabile Pagamenti E Servizi",
      category: "Privato"
  },
  {
      label: "Responsabile Personale E Organizzazione",
      value: "Responsabile Personale E Organizzazione",
      category: "Privato"
  },
  {
      label: "Responsabile Pianificazione E Controllo Di Gestione",
      value: "Responsabile Pianificazione E Controllo Di Gestione",
      category: "Privato"
  },
  {
      label: "Responsabile Privacy",
      value: "Responsabile Privacy",
      category: "Privato"
  },
  {
      label: "Responsabile Produzione",
      value: "Responsabile Produzione",
      category: "Privato"
  },
  {
      label: "Responsabile Progetti Pa",
      value: "Responsabile Progetti Pa",
      category: "Privato"
  },
  {
      label: "Responsabile Qualità",
      value: "Responsabile Qualità",
      category: "Privato"
  },
  {
      label: "Responsabile R&D",
      value: "Responsabile R&D",
      category: "Privato"
  },
  {
      label: "Responsabile Rapporti Istituzionali",
      value: "Responsabile Rapporti Istituzionali",
      category: "Privato"
  },
  {
      label: "Responsabile Recruitment & Learning",
      value: "Responsabile Recruitment & Learning",
      category: "Privato"
  },
  {
      label: "Responsabile Recupero Crediti",
      value: "Responsabile Recupero Crediti",
      category: "Privato"
  },
  {
      label: "Responsabile Regolatorio",
      value: "Responsabile Regolatorio",
      category: "Privato"
  },
  {
      label: "Responsabile Reinsurance",
      value: "Responsabile Reinsurance",
      category: "Privato"
  },
  {
      label: "Responsabile Relazioni Esterne",
      value: "Responsabile Relazioni Esterne",
      category: "Privato"
  },
  {
      label: "Responsabile Relazioni Istituzionali",
      value: "Responsabile Relazioni Istituzionali",
      category: "Privato"
  },
  {
      label: "Responsabile Relazioni Istituzionali Ed Esterne",
      value: "Responsabile Relazioni Istituzionali Ed Esterne",
      category: "Privato"
  },
  {
      label: "Responsabile Reparto Supply Chain Management",
      value: "Responsabile Reparto Supply Chain Management",
      category: "Privato"
  },
  {
      label: "Responsabile Ricerca E Selezione",
      value: "Responsabile Ricerca E Selezione",
      category: "Privato"
  },
  {
      label: "Responsabile Risk Governance & Compliance",
      value: "Responsabile Risk Governance & Compliance",
      category: "Privato"
  },
  {
      label: "Responsabile Risorse Umane",
      value: "Responsabile Risorse Umane",
      category: "Privato"
  },
  {
      label: "Responsabile Sales",
      value: "Responsabile Sales",
      category: "Privato"
  },
  {
      label: "Responsabile Sales Support",
      value: "Responsabile Sales Support",
      category: "Privato"
  },
  {
      label: "Responsabile Selezione E Employer Branding",
      value: "Responsabile Selezione E Employer Branding",
      category: "Privato"
  },
  {
      label: "Responsabile Servizi Doganali E Magazzino",
      value: "Responsabile Servizi Doganali E Magazzino",
      category: "Privato"
  },
  {
      label: "Responsabile Servizi Innovativi",
      value: "Responsabile Servizi Innovativi",
      category: "Privato"
  },
  {
      label: "Responsabile Servizio Affari Generali E Societari",
      value: "Responsabile Servizio Affari Generali E Societari",
      category: "Privato"
  },
  {
      label: "Responsabile Servizio Mercato Imprese",
      value: "Responsabile Servizio Mercato Imprese",
      category: "Privato"
  },
  {
      label: "Responsabile Sicurezza Alimentare E Assicurazione Qualità",
      value: "Responsabile Sicurezza Alimentare E Assicurazione Qualità",
      category: "Privato"
  },
  {
      label: "Responsabile Sicurezza Fisica",
      value: "Responsabile Sicurezza Fisica",
      category: "Privato"
  },
  {
      label: "Responsabile Sistemi Informativi",
      value: "Responsabile Sistemi Informativi",
      category: "Privato"
  },
  {
      label: "Responsabile Struttura Pianificazione E Marketing",
      value: "Responsabile Struttura Pianificazione E Marketing",
      category: "Privato"
  },
  {
      label: "Responsabile Sustainability Development",
      value: "Responsabile Sustainability Development",
      category: "Privato"
  },
  {
      label: "Responsabile Sviluppo E Formazione",
      value: "Responsabile Sviluppo E Formazione",
      category: "Privato"
  },
  {
      label: "Responsabile Sviluppo Risorse Umane",
      value: "Responsabile Sviluppo Risorse Umane",
      category: "Privato"
  },
  {
      label: "Responsabile Tecnico",
      value: "Responsabile Tecnico",
      category: "Privato"
  },
  {
      label: "Responsabile Tecnico Area Software",
      value: "Responsabile Tecnico Area Software",
      category: "Privato"
  },
  {
      label: "Responsabile Tecnologie",
      value: "Responsabile Tecnologie",
      category: "Privato"
  },
  {
      label: "Responsabile Telco",
      value: "Responsabile Telco",
      category: "Privato"
  },
  {
      label: "Responsabile Ufficio",
      value: "Responsabile Ufficio",
      category: "Privato"
  },
  {
      label: "Responsabile Ufficio Acquisti",
      value: "Responsabile Ufficio Acquisti",
      category: "Privato"
  },
  {
      label: "Responsabile Ufficio Post Vendita",
      value: "Responsabile Ufficio Post Vendita",
      category: "Privato"
  },
  {
      label: "Responsabile Ufficio Studi",
      value: "Responsabile Ufficio Studi",
      category: "Privato"
  },
  {
      label: "Responsabile Ufficio Tecnico",
      value: "Responsabile Ufficio Tecnico",
      category: "Privato"
  },
  {
      label: "Responsabile Ufficio Territoriale",
      value: "Responsabile Ufficio Territoriale",
      category: "Privato"
  },
  {
      label: "Responsabile Vendite",
      value: "Responsabile Vendite",
      category: "Privato"
  },
  {
      label: "Resposabile Academy",
      value: "Resposabile Academy",
      category: "Privato"
  },
  {
      label: "Retail & Trade Marketing",
      value: "Retail & Trade Marketing",
      category: "Privato"
  },
  {
      label: "Retail Channel Manager",
      value: "Retail Channel Manager",
      category: "Privato"
  },
  {
      label: "Retail Director",
      value: "Retail Director",
      category: "Privato"
  },
  {
      label: "Retail Operations Director",
      value: "Retail Operations Director",
      category: "Privato"
  },
  {
      label: "Revisore Legale",
      value: "Revisore Legale",
      category: "Privato"
  },
  {
      label: "Ricerca e Sviluppo",
      value: "Ricerca e Sviluppo",
      category: "Privato"
  },
  {
      label: "Ricercatore",
      value: "Ricercatore",
      category: "Privato/Pubblico"
  },
  {
      label: "Risk Advisor",
      value: "Risk Advisor",
      category: "Privato"
  },
  {
      label: "Risk Consulting Country Manager, Italy ",
      value: "Risk Consulting Country Manager, Italy ",
      category: "Privato"
  },
  {
      label: "Risk Consulting Manager ",
      value: "Risk Consulting Manager ",
      category: "Privato"
  },
  {
      label: "Risk Management",
      value: "Risk Management",
      category: "Privato"
  },
  {
      label: "Risk Manager",
      value: "Risk Manager",
      category: "Privato"
  },
  {
      label: "Risk Operating Officer",
      value: "Risk Operating Officer",
      category: "Privato"
  },
  {
      label: "Risk Specialist",
      value: "Risk Specialist",
      category: "Privato"
  },
  {
      label: "Risk Sr. Specialist",
      value: "Risk Sr. Specialist",
      category: "Privato"
  },
  {
      label: "Rspp-Hse Manager",
      value: "Rspp-Hse Manager",
      category: "Privato"
  },
  {
      label: "Sales",
      value: "Sales",
      category: "Privato"
  },
  {
      label: "Sales & Marketing",
      value: "Sales & Marketing",
      category: "Privato"
  },
  {
      label: "Sales & Marketing Director",
      value: "Sales & Marketing Director",
      category: "Privato"
  },
  {
      label: "Sales & Marketing Manager",
      value: "Sales & Marketing Manager",
      category: "Privato"
  },
  {
      label: "Sales Account",
      value: "Sales Account",
      category: "Privato"
  },
  {
      label: "Sales Account Executive",
      value: "Sales Account Executive",
      category: "Privato"
  },
  {
      label: "Sales Account Large Corporate E Pal",
      value: "Sales Account Large Corporate E Pal",
      category: "Privato"
  },
  {
      label: "Sales Account Manager",
      value: "Sales Account Manager",
      category: "Privato"
  },
  {
      label: "Sales Account Pa",
      value: "Sales Account Pa",
      category: "Privato"
  },
  {
      label: "Sales Area Manager",
      value: "Sales Area Manager",
      category: "Privato"
  },
  {
      label: "Sales Area Manager Healthcare",
      value: "Sales Area Manager Healthcare",
      category: "Privato"
  },
  {
      label: "Sales Business Unit Manager",
      value: "Sales Business Unit Manager",
      category: "Privato"
  },
  {
      label: "Sales Channel Account Manager",
      value: "Sales Channel Account Manager",
      category: "Privato"
  },
  {
      label: "Sales Consultant Digital",
      value: "Sales Consultant Digital",
      category: "Privato"
  },
  {
      label: "Sales Consulting",
      value: "Sales Consulting",
      category: "Privato"
  },
  {
      label: "Sales Coordinator",
      value: "Sales Coordinator",
      category: "Privato"
  },
  {
      label: "Sales Data & Ai",
      value: "Sales Data & Ai",
      category: "Privato"
  },
  {
      label: "Sales Director",
      value: "Sales Director",
      category: "Privato"
  },
  {
      label: "Sales Director & Data Evangelist",
      value: "Sales Director & Data Evangelist",
      category: "Privato"
  },
  {
      label: "Sales Director Europe & Supply Chain Director",
      value: "Sales Director Europe & Supply Chain Director",
      category: "Privato"
  },
  {
      label: "Sales Director Modern Trade - Italy",
      value: "Sales Director Modern Trade - Italy",
      category: "Privato"
  },
  {
      label: "Sales Director-South-Central Italy ",
      value: "Sales Director-South-Central Italy ",
      category: "Privato"
  },
  {
      label: "Sales Engineer",
      value: "Sales Engineer",
      category: "Privato"
  },
  {
      label: "Sales Executive",
      value: "Sales Executive",
      category: "Privato"
  },
  {
      label: "Sales Leader",
      value: "Sales Leader",
      category: "Privato"
  },
  {
      label: "Sales Manager",
      value: "Sales Manager",
      category: "Privato"
  },
  {
      label: "Sales Marketing & Commercial Development Director, European Packaging",
      value: "Sales Marketing & Commercial Development Director, European Packaging",
      category: "Privato"
  },
  {
      label: "Sales Office",
      value: "Sales Office",
      category: "Privato"
  },
  {
      label: "Sales Officer Assistant ",
      value: "Sales Officer Assistant ",
      category: "Privato"
  },
  {
      label: "Sales Operations And Transformation Director",
      value: "Sales Operations And Transformation Director",
      category: "Privato"
  },
  {
      label: "Sales Process Improvement ",
      value: "Sales Process Improvement ",
      category: "Privato"
  },
  {
      label: "Sales Regional Vice President - Public Sector",
      value: "Sales Regional Vice President - Public Sector",
      category: "Privato"
  },
  {
      label: "Sales Senior Manager",
      value: "Sales Senior Manager",
      category: "Privato"
  },
  {
      label: "Sales Specialist",
      value: "Sales Specialist",
      category: "Privato"
  },
  {
      label: "Sales Specialist Transizione Digitale Pubblica Amministrazione",
      value: "Sales Specialist Transizione Digitale Pubblica Amministrazione",
      category: "Privato"
  },
  {
      label: "Sales Staff",
      value: "Sales Staff",
      category: "Privato"
  },
  {
      label: "Sales Supervisor",
      value: "Sales Supervisor",
      category: "Privato"
  },
  {
      label: "Sales Supervisor Office Print",
      value: "Sales Supervisor Office Print",
      category: "Privato"
  },
  {
      label: "Sales Support",
      value: "Sales Support",
      category: "Privato"
  },
  {
      label: "Security Architect",
      value: "Security Architect",
      category: "Privato"
  },
  {
      label: "Security Director",
      value: "Security Director",
      category: "Privato"
  },
  {
      label: "Security Manager",
      value: "Security Manager",
      category: "Privato"
  },
  {
      label: "Security Solution Architect",
      value: "Security Solution Architect",
      category: "Privato"
  },
  {
      label: "Security Solution Sales",
      value: "Security Solution Sales",
      category: "Privato"
  },
  {
      label: "Security Specialist",
      value: "Security Specialist",
      category: "Privato"
  },
  {
      label: "Segretaria Di Direzione",
      value: "Segretaria Di Direzione",
      category: "Privato"
  },
  {
      label: "Segretario",
      value: "Segretario",
      category: "Privato/Pubblico"
  },
  {
      label: "Segretario Generale",
      value: "Segretario Generale",
      category: "Privato"
  },
  {
      label: "Segreteria Amministrativa E Commerciale",
      value: "Segreteria Amministrativa E Commerciale",
      category: "Privato"
  },
  {
      label: "Segreteria Editoriale",
      value: "Segreteria Editoriale",
      category: "Privato"
  },
  {
      label: "Sell-Out Director",
      value: "Sell-Out Director",
      category: "Privato"
  },
  {
      label: "Senatore",
      value: "Senatore",
      category: "Pubblico"
  },
  {
      label: "Senior Account",
      value: "Senior Account",
      category: "Privato"
  },
  {
      label: "Senior Account Executive",
      value: "Senior Account Executive",
      category: "Privato"
  },
  {
      label: "Senior Account Executive Public Sector",
      value: "Senior Account Executive Public Sector",
      category: "Privato"
  },
  {
      label: "Senior Account Manager",
      value: "Senior Account Manager",
      category: "Privato"
  },
  {
      label: "Senior Accountant",
      value: "Senior Accountant",
      category: "Privato"
  },
  {
      label: "Senior Advisor",
      value: "Senior Advisor",
      category: "Privato"
  },
  {
      label: "Senior Associate",
      value: "Senior Associate",
      category: "Privato"
  },
  {
      label: "Senior Banker",
      value: "Senior Banker",
      category: "Privato"
  },
  {
      label: "Senior Business Advisor",
      value: "Senior Business Advisor",
      category: "Privato"
  },
  {
      label: "Senior Business Developer",
      value: "Senior Business Developer",
      category: "Privato"
  },
  {
      label: "Senior Business Development Manager",
      value: "Senior Business Development Manager",
      category: "Privato"
  },
  {
      label: "Senior Business Partner",
      value: "Senior Business Partner",
      category: "Privato"
  },
  {
      label: "Senior Claims Specialist, Italy ",
      value: "Senior Claims Specialist, Italy ",
      category: "Privato"
  },
  {
      label: "Senior Communication Manager ",
      value: "Senior Communication Manager ",
      category: "Privato"
  },
  {
      label: "Senior Consultant",
      value: "Senior Consultant",
      category: "Privato"
  },
  {
      label: "Senior Consultant It & Digital",
      value: "Senior Consultant It & Digital",
      category: "Privato"
  },
  {
      label: "Senior Consultat",
      value: "Senior Consultat",
      category: "Privato"
  },
  {
      label: "Senior Consulting",
      value: "Senior Consulting",
      category: "Privato"
  },
  {
      label: "Senior Customer Service Manager",
      value: "Senior Customer Service Manager",
      category: "Privato"
  },
  {
      label: "Senior Developer",
      value: "Senior Developer",
      category: "Privato"
  },
  {
      label: "Senior Director",
      value: "Senior Director",
      category: "Privato"
  },
  {
      label: "Senior Director Business Operations",
      value: "Senior Director Business Operations",
      category: "Privato"
  },
  {
      label: "Senior Director Customer Managment ",
      value: "Senior Director Customer Managment ",
      category: "Privato"
  },
  {
      label: "Senior Director Digital Transformation Officer",
      value: "Senior Director Digital Transformation Officer",
      category: "Privato"
  },
  {
      label: "Senior Finance Administrator",
      value: "Senior Finance Administrator",
      category: "Privato"
  },
  {
      label: "Senior Global Director People & Culture",
      value: "Senior Global Director People & Culture",
      category: "Privato"
  },
  {
      label: "Senior Government Relations & Public Policy Lead Italy",
      value: "Senior Government Relations & Public Policy Lead Italy",
      category: "Privato"
  },
  {
      label: "Senior Hr Business Partner",
      value: "Senior Hr Business Partner",
      category: "Privato"
  },
  {
      label: "Senior Ict Consultant",
      value: "Senior Ict Consultant",
      category: "Privato"
  },
  {
      label: "Senior Impact Analyst",
      value: "Senior Impact Analyst",
      category: "Privato"
  },
  {
      label: "Senior Key Account & Project Manager",
      value: "Senior Key Account & Project Manager",
      category: "Privato"
  },
  {
      label: "Senior Key Account Manager",
      value: "Senior Key Account Manager",
      category: "Privato"
  },
  {
      label: "Senior Legal Counsel, Italy ",
      value: "Senior Legal Counsel, Italy ",
      category: "Privato"
  },
  {
      label: "Senior Legal Specialist",
      value: "Senior Legal Specialist",
      category: "Privato"
  },
  {
      label: "Senior Manager",
      value: "Senior Manager",
      category: "Privato"
  },
  {
      label: "Senior Marketing Executive",
      value: "Senior Marketing Executive",
      category: "Privato"
  },
  {
      label: "Senior Partner",
      value: "Senior Partner",
      category: "Privato"
  },
  {
      label: "Senior Partner Sales Specialist Uds Southern Europe",
      value: "Senior Partner Sales Specialist Uds Southern Europe",
      category: "Privato"
  },
  {
      label: "Senior Private Banker",
      value: "Senior Private Banker",
      category: "Privato"
  },
  {
      label: "Senior Product Manager",
      value: "Senior Product Manager",
      category: "Privato"
  },
  {
      label: "Senior Research Manager",
      value: "Senior Research Manager",
      category: "Privato"
  },
  {
      label: "Senior Researcher",
      value: "Senior Researcher",
      category: "Privato"
  },
  {
      label: "Senior Sales Engineer",
      value: "Senior Sales Engineer",
      category: "Privato"
  },
  {
      label: "Senior Sales Executive",
      value: "Senior Sales Executive",
      category: "Privato"
  },
  {
      label: "Senior Sales Manager",
      value: "Senior Sales Manager",
      category: "Privato"
  },
  {
      label: "Senior Sales Representative",
      value: "Senior Sales Representative",
      category: "Privato"
  },
  {
      label: "Senior Service Delivery Manager",
      value: "Senior Service Delivery Manager",
      category: "Privato"
  },
  {
      label: "Senior Software Engineer",
      value: "Senior Software Engineer",
      category: "Privato"
  },
  {
      label: "Senior System Administrator",
      value: "Senior System Administrator",
      category: "Privato"
  },
  {
      label: "Senior System Engineer",
      value: "Senior System Engineer",
      category: "Privato"
  },
  {
      label: "Senior Talent Strategy Advisor",
      value: "Senior Talent Strategy Advisor",
      category: "Privato"
  },
  {
      label: "Senior Trade Commissioner",
      value: "Senior Trade Commissioner",
      category: "Privato"
  },
  {
      label: "Senior Vice President Engineering",
      value: "Senior Vice President Engineering",
      category: "Privato"
  },
  {
      label: "Senior, Product Manager, Crm Italy ",
      value: "Senior, Product Manager, Crm Italy ",
      category: "Privato"
  },
  {
      label: "Seo Manager",
      value: "Seo Manager",
      category: "Privato"
  },
  {
      label: "Seo Specialist",
      value: "Seo Specialist",
      category: "Privato"
  },
  {
      label: "Service Departement Manager",
      value: "Service Departement Manager",
      category: "Privato"
  },
  {
      label: "Service Designer",
      value: "Service Designer",
      category: "Privato"
  },
  {
      label: "Service Director",
      value: "Service Director",
      category: "Privato"
  },
  {
      label: "Service Manager",
      value: "Service Manager",
      category: "Privato"
  },
  {
      label: "Servizi IT",
      value: "Servizi IT",
      category: "Privato"
  },
  {
      label: "Servizio Civile",
      value: "Servizio Civile",
      category: "Privato"
  },
  {
      label: "Servizio Talent & Internal Communication",
      value: "Servizio Talent & Internal Communication",
      category: "Privato"
  },
  {
      label: "Settore Ambiente",
      value: "Settore Ambiente",
      category: "Privato"
  },
  {
      label: "Sindaco",
      value: "Sindaco",
      category: "Pubblico"
  },
  {
      label: "Sistemi informativi",
      value: "Sistemi informativi",
      category: "Privato"
  },
  {
      label: "Social Media",
      value: "Social Media",
      category: "Privato"
  },
  {
      label: "Social Media Manager",
      value: "Social Media Manager",
      category: "Privato"
  },
  {
      label: "Social Media Manager Comunicazione Business",
      value: "Social Media Manager Comunicazione Business",
      category: "Privato"
  },
  {
      label: "Social Media Specialist",
      value: "Social Media Specialist",
      category: "Privato"
  },
  {
      label: "Socio",
      value: "Socio",
      category: "Privato"
  },
  {
      label: "Socio Amministratore",
      value: "Socio Amministratore",
      category: "Privato"
  },
  {
      label: "Software Manager",
      value: "Software Manager",
      category: "Privato"
  },
  {
      label: "Software Specialist",
      value: "Software Specialist",
      category: "Privato"
  },
  {
      label: "Solution Architect",
      value: "Solution Architect",
      category: "Privato"
  },
  {
      label: "Solution E Systems Architect",
      value: "Solution E Systems Architect",
      category: "Privato"
  },
  {
      label: "Solution Executive",
      value: "Solution Executive",
      category: "Privato"
  },
  {
      label: "Solution Manager",
      value: "Solution Manager",
      category: "Privato"
  },
  {
      label: "Solution Specilist",
      value: "Solution Specilist",
      category: "Privato"
  },
  {
      label: "Solutions Architect",
      value: "Solutions Architect",
      category: "Privato"
  },
  {
      label: "Solutions Development Sr Directo",
      value: "Solutions Development Sr Directo",
      category: "Privato"
  },
  {
      label: "Specialist",
      value: "Specialist",
      category: "Privato/Pubblico"
  },
  {
      label: "Specialist Ufficio Gare",
      value: "Specialist Ufficio Gare",
      category: "Privato/Pubblico"
  },
  {
      label: "Specialista Amministrativo",
      value: "Specialista Amministrativo",
      category: "Privato/Pubblico"
  },
  {
      label: "Specialista Di Prodotto",
      value: "Specialista Di Prodotto",
      category: "Privato"
  },
  {
      label: "Specialista Formazione",
      value: "Specialista Formazione",
      category: "Privato"
  },
  {
      label: "Specialista Giuridico Legale Finanziario ",
      value: "Specialista Giuridico Legale Finanziario ",
      category: "Privato"
  },
  {
      label: "Specialista Innovazione",
      value: "Specialista Innovazione",
      category: "Privato"
  },
  {
      label: "Specialista Investimenti",
      value: "Specialista Investimenti",
      category: "Privato"
  },
  {
      label: "Specialisti Di Prodotto",
      value: "Specialisti Di Prodotto",
      category: "Privato"
  },
  {
      label: "Specialized Nutrition Unit Director",
      value: "Specialized Nutrition Unit Director",
      category: "Privato"
  },
  {
      label: "Specialty & Hospital Business Unit",
      value: "Specialty & Hospital Business Unit",
      category: "Privato"
  },
  {
      label: "Sponsorship & Business Development",
      value: "Sponsorship & Business Development",
      category: "Privato"
  },
  {
      label: "Sr Director, Manufacturing Operations",
      value: "Sr Director, Manufacturing Operations",
      category: "Privato"
  },
  {
      label: "Sr. Channel Manager",
      value: "Sr. Channel Manager",
      category: "Privato"
  },
  {
      label: "Sr. Product Manager Structural Heart",
      value: "Sr. Product Manager Structural Heart",
      category: "Privato"
  },
  {
      label: "Staff",
      value: "Staff",
      category: "Privato"
  },
  {
      label: "Staff Engineer",
      value: "Staff Engineer",
      category: "Privato"
  },
  {
      label: "Staff Tecnico",
      value: "Staff Tecnico",
      category: "Privato"
  },
  {
      label: "Storage Architect Sr Supervisor",
      value: "Storage Architect Sr Supervisor",
      category: "Privato"
  },
  {
      label: "Storage Manager",
      value: "Storage Manager",
      category: "Privato"
  },
  {
      label: "Store Leader",
      value: "Store Leader",
      category: "Privato"
  },
  {
      label: "Strategic Account Manager",
      value: "Strategic Account Manager",
      category: "Privato"
  },
  {
      label: "Strategic Marketing Director",
      value: "Strategic Marketing Director",
      category: "Privato"
  },
  {
      label: "Strategic Marketing Manager",
      value: "Strategic Marketing Manager",
      category: "Privato"
  },
  {
      label: "Strategic Selling Director",
      value: "Strategic Selling Director",
      category: "Privato"
  },
  {
      label: "Strategic Technical Leadership Department Manager",
      value: "Strategic Technical Leadership Department Manager",
      category: "Privato"
  },
  {
      label: "Strategic Tender & Pricing Manager",
      value: "Strategic Tender & Pricing Manager",
      category: "Privato"
  },
  {
      label: "Stretegic & Planning Manager",
      value: "Stretegic & Planning Manager",
      category: "Privato"
  },
  {
      label: "Studente",
      value: "Studente",
      category: "Privato/Pubblico"
  },
  {
      label: "Supervisor",
      value: "Supervisor",
      category: "Privato"
  },
  {
      label: "Supplier Quality Manager",
      value: "Supplier Quality Manager",
      category: "Privato"
  },
  {
      label: "Supply Chain",
      value: "Supply Chain",
      category: "Privato"
  },
  {
      label: "Supply Chain Director",
      value: "Supply Chain Director",
      category: "Privato"
  },
  {
      label: "Supply Chain Manager",
      value: "Supply Chain Manager",
      category: "Privato"
  },
  {
      label: "Supply Leader",
      value: "Supply Leader",
      category: "Privato"
  },
  {
      label: "Sustainability Manager",
      value: "Sustainability Manager",
      category: "Privato"
  },
  {
      label: "Sustainability Program Leader",
      value: "Sustainability Program Leader",
      category: "Privato"
  },
  {
      label: "Sustainability Specialist",
      value: "Sustainability Specialist",
      category: "Privato"
  },
  {
      label: "Sustainability Stakeholders Engagement",
      value: "Sustainability Stakeholders Engagement",
      category: "Privato"
  },
  {
      label: "Sviluppatore",
      value: "Sviluppatore",
      category: "Privato"
  },
  {
      label: "Sviluppo Mercato Pubblico",
      value: "Sviluppo Mercato Pubblico",
      category: "Privato"
  },
  {
      label: "System Administrator",
      value: "System Administrator",
      category: "Privato"
  },
  {
      label: "System Architect",
      value: "System Architect",
      category: "Privato"
  },
  {
      label: "System Manager",
      value: "System Manager",
      category: "Privato"
  },
  {
      label: "System Specialist",
      value: "System Specialist",
      category: "Privato"
  },
  {
      label: "Talent Acquisition",
      value: "Talent Acquisition",
      category: "Privato"
  },
  {
      label: "Talent Acquisition & Employer Branding Senior Manager",
      value: "Talent Acquisition & Employer Branding Senior Manager",
      category: "Privato"
  },
  {
      label: "Talent Manager",
      value: "Talent Manager",
      category: "Privato"
  },
  {
      label: "Team Leader",
      value: "Team Leader",
      category: "Privato"
  },
  {
      label: "Team Leader New Business Sales Italy ",
      value: "Team Leader New Business Sales Italy ",
      category: "Privato"
  },
  {
      label: "Team Manager",
      value: "Team Manager",
      category: "Privato"
  },
  {
      label: "Technical Account Manager",
      value: "Technical Account Manager",
      category: "Privato"
  },
  {
      label: "Technical Customers Support Specialist",
      value: "Technical Customers Support Specialist",
      category: "Privato"
  },
  {
      label: "Technical Production Manager",
      value: "Technical Production Manager",
      category: "Privato"
  },
  {
      label: "Technical Project Manager",
      value: "Technical Project Manager",
      category: "Privato"
  },
  {
      label: "Technical Recruiter",
      value: "Technical Recruiter",
      category: "Privato"
  },
  {
      label: "Technical Sales",
      value: "Technical Sales",
      category: "Privato"
  },
  {
      label: "Technical Support Manager",
      value: "Technical Support Manager",
      category: "Privato"
  },
  {
      label: "Technology Engineering Manager",
      value: "Technology Engineering Manager",
      category: "Privato"
  },
  {
      label: "Technology Strategist",
      value: "Technology Strategist",
      category: "Privato"
  },
  {
      label: "Tecnico",
      value: "Tecnico",
      category: "Privato"
  },
  {
      label: "Tecnico Elettronico",
      value: "Tecnico Elettronico",
      category: "Privato"
  },
  {
      label: "Tecnico Immobili Impianti",
      value: "Tecnico Immobili Impianti",
      category: "Privato"
  },
  {
      label: "Tecnico Immobiliare",
      value: "Tecnico Immobiliare",
      category: "Privato"
  },
  {
      label: "Tender Manager",
      value: "Tender Manager",
      category: "Privato"
  },
  {
      label: "Tender Office & Contract Senior Specialist",
      value: "Tender Office & Contract Senior Specialist",
      category: "Privato"
  },
  {
      label: "Tender Office Manager",
      value: "Tender Office Manager",
      category: "Privato"
  },
  {
      label: "Territorial Institutional Affairs & Compliance Specialist",
      value: "Territorial Institutional Affairs & Compliance Specialist",
      category: "Privato"
  },
  {
      label: "Territory Executive",
      value: "Territory Executive",
      category: "Privato"
  },
  {
      label: "Tesoriere",
      value: "Tesoriere",
      category: "Pubblico"
  },
  {
      label: "Tester",
      value: "Tester",
      category: "Privato"
  },
  {
      label: "Therapy Awareness Supervisor",
      value: "Therapy Awareness Supervisor",
      category: "Privato"
  },
  {
      label: "Titolare",
      value: "Titolare",
      category: "Privato"
  },
  {
      label: "Top Global Banker",
      value: "Top Global Banker",
      category: "Privato"
  },
  {
      label: "Top Management",
      value: "Top Management",
      category: "Privato"
  },
  {
      label: "Top Private Banker",
      value: "Top Private Banker",
      category: "Privato"
  },
  {
      label: "Top Used Director",
      value: "Top Used Director",
      category: "Privato"
  },
  {
      label: "Total Rewards Coe Director",
      value: "Total Rewards Coe Director",
      category: "Privato"
  },
  {
      label: "Trade Commissioner",
      value: "Trade Commissioner",
      category: "Privato"
  },
  {
      label: "Trade Marketing",
      value: "Trade Marketing",
      category: "Privato"
  },
  {
      label: "Trade Marketing Senior Manager",
      value: "Trade Marketing Senior Manager",
      category: "Privato"
  },
  {
      label: "Trade Strategy & Analysis Manager",
      value: "Trade Strategy & Analysis Manager",
      category: "Privato"
  },
  {
      label: "Trading Marketing",
      value: "Trading Marketing",
      category: "Privato"
  },
  {
      label: "Trainer",
      value: "Trainer",
      category: "Privato"
  },
  {
      label: "Training And Development Manager",
      value: "Training And Development Manager",
      category: "Privato"
  },
  {
      label: "Training Consultant",
      value: "Training Consultant",
      category: "Privato"
  },
  {
      label: "Training Content Manager",
      value: "Training Content Manager",
      category: "Privato"
  },
  {
      label: "Training Poject Coordinator",
      value: "Training Poject Coordinator",
      category: "Privato"
  },
  {
      label: "Training Recruiting And Development Director ",
      value: "Training Recruiting And Development Director ",
      category: "Privato"
  },
  {
      label: "Training Solutions Specialist",
      value: "Training Solutions Specialist",
      category: "Privato"
  },
  {
      label: "Training, Recruiting And Welfare Manager",
      value: "Training, Recruiting And Welfare Manager",
      category: "Privato"
  },
  {
      label: "Transformation Officer",
      value: "Transformation Officer",
      category: "Privato"
  },
  {
      label: "Treasury And Credit Manager",
      value: "Treasury And Credit Manager",
      category: "Privato"
  },
  {
      label: "Ufficio Acquisti",
      value: "Ufficio Acquisti",
      category: "Privato/Pubblico"
  },
  {
      label: "Ufficio Bandi Di Gara",
      value: "Ufficio Bandi Di Gara",
      category: "Privato/Pubblico"
  },
  {
      label: "Ufficio Commerciale",
      value: "Ufficio Commerciale",
      category: "Privato"
  },
  {
      label: "Ufficio IT",
      value: "Ufficio IT",
      category: "Privato"
  },
  {
      label: "Ufficio Legislativo",
      value: "Ufficio Legislativo",
      category: "Pubblico"
  },
  {
      label: "Ufficio Marketing",
      value: "Ufficio Marketing",
      category: "Privato"
  },
  {
      label: "Ufficio Stampa",
      value: "Ufficio Stampa",
      category: "Privato"
  },
  {
      label: "Ufficio Studi",
      value: "Ufficio Studi",
      category: "Privato"
  },
  {
      label: "Ufficio Tecnico",
      value: "Ufficio Tecnico",
      category: "Privato"
  },
  {
      label: "Urbanista",
      value: "Urbanista",
      category: "Privato/Pubblico"
  },
  {
      label: "Ux Designer",
      value: "Ux Designer",
      category: "Privato"
  },
  {
      label: "Value Access Lead",
      value: "Value Access Lead",
      category: "Privato"
  },
  {
      label: "Value Creation Associate",
      value: "Value Creation Associate",
      category: "Privato"
  },
  {
      label: "Vendite",
      value: "Vendite",
      category: "Privato"
  },
  {
      label: "Vice Direttore",
      value: "Vice Direttore",
      category: "Privato/Pubblico"
  },
  {
      label: "Vice Direttore Generale",
      value: "Vice Direttore Generale",
      category: "Privato/Pubblico"
  },
  {
      label: "Vice President",
      value: "Vice President",
      category: "Privato"
  },
  {
      label: "Vice President Innovation & Quality",
      value: "Vice President Innovation & Quality",
      category: "Privato"
  },
  {
      label: "Vice President Marketing",
      value: "Vice President Marketing",
      category: "Privato"
  },
  {
      label: "Vice President Operations",
      value: "Vice President Operations",
      category: "Privato"
  },
  {
      label: "Vice President Sales & Marketing",
      value: "Vice President Sales & Marketing",
      category: "Privato"
  },
  {
      label: "Vice President Sales & Service",
      value: "Vice President Sales & Service",
      category: "Privato"
  },
  {
      label: "Vice President Sales & Service Linde Italy",
      value: "Vice President Sales & Service Linde Italy",
      category: "Privato"
  },
  {
      label: "Vice President Service, Deliveries, After Sales",
      value: "Vice President Service, Deliveries, After Sales",
      category: "Privato"
  },
  {
      label: "Vice President Solution Engineering",
      value: "Vice President Solution Engineering",
      category: "Privato"
  },
  {
      label: "Vice Presidente",
      value: "Vice Presidente",
      category: "Privato/Pubblico"
  },
  {
      label: "Vice Presidente Legal And Corporate Affairs",
      value: "Vice Presidente Legal And Corporate Affairs",
      category: "Privato"
  },
  {
      label: "Vice Presidente Operativo",
      value: "Vice Presidente Operativo",
      category: "Privato"
  },
  {
      label: "Vice Sindaco",
      value: "Vice Sindaco",
      category: "Pubblico"
  },
  {
      label: "Video Producer",
      value: "Video Producer",
      category: "Privato"
  },
  {
      label: "Videomaker",
      value: "Videomaker",
      category: "Privato"
  },
  {
      label: "Visual Identity Specialist",
      value: "Visual Identity Specialist",
      category: "Privato"
  },
  {
      label: "Wealth Advisor",
      value: "Wealth Advisor",
      category: "Privato"
  },
  {
      label: "Wealth Manager",
      value: "Wealth Manager",
      category: "Privato"
  },
  {
      label: "Web Designer",
      value: "Web Designer",
      category: "Privato"
  },
  {
      label: "Web Developer",
      value: "Web Developer",
      category: "Privato"
  },
  {
      label: "Web Developer & Accessibility Consultant",
      value: "Web Developer & Accessibility Consultant",
      category: "Privato"
  },
  {
      label: "Web Manager",
      value: "Web Manager",
      category: "Privato"
  },
  {
      label: "Web Marketing Specialist",
      value: "Web Marketing Specialist",
      category: "Privato"
  },
  {
      label: "Web Master",
      value: "Web Master",
      category: "Privato"
  },
  {
      label: "Web Specialist",
      value: "Web Specialist",
      category: "Privato"
  }
]